// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Grid,
  Container,
  Button,
  Select,
  MenuItem,
  TextField,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
  Dialog,
  DialogContent,
  LinearProgress,
  TextareaAutosize,
  Divider,
  styled, Tooltip, Paper, MenuProps,
  DialogContentText,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AppHeader from "../../../components/src/AppHeader.web";
import {
  imgLogo,
  createImage,
  imgUser,
  imgNotification, imgRightArrow,
  imgDocument,
  imgUpload,
  imgClose, group_plus, loadingImg, googlecalicon, windowscalicon, yahooIcon, appleicon, groupmeeticon, calendaricon, downloadImage, meetingCalendar
} from "./assets";
import {
  View, Text, StyleSheet, Image
} from "react-native";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Formik } from "formik";
import CustomFormController, {
  Props, configJSON, baseURL
} from "./CustomFormController.web";
import GenericLabel from "../../../components/src/GenericLabel";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DatePicker from 'react-datepicker';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import GetAppIcon from '@material-ui/icons/GetApp';
import ICalendarLink from "react-icalendar-link";
import moment from "moment";
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInputField(baseDetails: any, values: any, setFieldValue: any) {
    const inputId = baseDetails.attributes.id.toString();
    const menuSelectedArray = this.renderSelectedMenu(baseDetails);

    const handleInputChange = (event: any) => {
      let value =  event.target.value
      setFieldValue(inputId,);
      this.setState((prevState) => ({
        requiredInputFieldsValue: {
          ...prevState.requiredInputFieldsValue,
          [inputId]: value,
        },
      }));
      const inputData = { id: inputId, user_input: value };
      this.saveData(inputData);
    };
    const handlebudgetInputChange = (event: any) => {
      let newValue = event.target.value;
      newValue = newValue.replace(/[^\d.]/g, '');
      newValue = newValue ? '£' + parseInt(newValue).toLocaleString() : '';
      setFieldValue(inputId, newValue);
      const inputData = { id: inputId, user_input: newValue };
      this.saveData(inputData);
      this.handleIsCalculateCost();
      this.setState((prevState) => ({
        requiredInputFieldsValue: {
          ...prevState.requiredInputFieldsValue,
          [inputId]: newValue,
        },
      }));
    };
    const handledateChange = (event: any) => {
      const convertToUserTimezone = (date: any) => {
        const currentDate = date instanceof Date ? date : new Date(date);
        const userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(currentDate.getTime() - userTimezoneOffset);
        return adjustedDate;
      };
      const convertedDate = convertToUserTimezone(event);
      setFieldValue(inputId, convertedDate);
      const inputData = { id: inputId, user_input: convertedDate };
      this.saveData(inputData);
      this.handleIsCalculateCost();
      this.setState((prevState) => ({
        requiredInputFieldsValue: {
          ...prevState.requiredInputFieldsValue,
          [inputId]: convertedDate
        },
      }));
    };
    const handleItemClick = (option: any, inputId: any) => {
      if (typeof option == "string") {
        this.postCodeApi(option)
      }
      const address = typeof option == "string" ? option : `${option.postcode}, ${option.admin_district}, ${option.parliamentary_constituency}`;
      setFieldValue(inputId, address);
      const inputData = { id: inputId, user_input: address };
      this.saveData(inputData);
      this.setState({ address: [] })
    }

    const renderTextField = () => {
      const isSpecialCase = baseDetails.attributes.input_field.name === "Client Name" || 
      baseDetails.attributes.input_field.name === "Company Name";
      return(
      <TextField
        data-testid={inputId}
        data-test-id='input-field'
        className={`customInput ${!isSpecialCase && this.requiredInputValidation(inputId) ? 'customInputError' : ''}`}
        fullWidth
        variant="standard"
        color="primary"
        InputProps={{
          style: { borderBottom: 'none' },
          disableUnderline: true,
        }}
        inputProps={{
          style: { backgroundColor: '#fff', outline: 'none' },
        }}
        value={values[inputId]}
        name={inputId}
        onChange={handleInputChange}
      />
    )};
    const renderDisabledTextField = () => (
      <TextField
        data-testid={inputId}
        className={`customInput ${this.requiredInputValidation(inputId) ? 'customInputError' : ''}`}
        fullWidth
        disabled
        variant="standard"
        color="primary"
        InputProps={{
          style: { borderBottom: 'none' },
          disableUnderline: true,
        }}
        inputProps={{
          style: { backgroundColor: '#fff', outline: 'none', color: "#000" },
        }}
        value={values[inputId]}
        name={inputId}
      />
    );

    const renderSingleLocationField = () => {
      return this.singleLoc(inputId)
    };

    const renderBudgetTextField = () => (
      <TextField
        data-testid={inputId}
        className={`customInput ${this.requiredInputValidation(inputId) ? 'customInputError' : ''}`}
        fullWidth
        variant="standard"
        color="primary"
        InputProps={{
          style: { borderBottom: 'none' },
          disableUnderline: true,
        }}
        inputProps={{
          style: { backgroundColor: '#fff', outline: 'none' },
        }}
        value={values[inputId]}
        name={inputId}
        data-test-id="event-budget"
        onChange={handlebudgetInputChange}
      />
    );
    const getBorderStyle = (addressLength: any, openModal: any) => {
      return addressLength > 0 && openModal ? "none" : "3px solid black";
    }

    const renderTextFieldLocation = () => (
      <InputContainer data-testId="input-renderTextFieldLocation">
        <InputField
          type="text"
          value={values[inputId]}
          name={inputId}
          onChange={(e) => { handleInputChange(e); this.locationFn(e) }}
          placeholder="e.g. EC2A 4PS"
          onClick={this.toggleDropdown}
          data-testid={inputId}
          data-test-id="locationInputID"
          style={{
            border: this.state.errorPostCode ? "3px solid red" : "3px solid black",
            borderBottom: this.state.errorPostCode ? "3px solid red" : getBorderStyle(this.state.address.length, this.state.openModal),
            borderColor: this.requiredInputValidation(inputId) ? '#FFBDC7' : '',
            paddingRight: '30px',
            height: "42px",
            borderRadius: 0
          }}
        />
        <LocationOnIcon style={{ color: 'black', position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />

        {Array.isArray(this.state.address) && this.state.openModal && (
          <DropdownContent
            style={{ display: this.state.address.length > 0 ? "block" : "none" }}
            data-testId="dropdownContent"
          >
            <DropdownList data-testId="dropdownList">
              {this.state.address.map((option: any, index: any) => (
                <DropdownItem key={index}
                  onClick={() => handleItemClick(option, inputId)}
                  data-testId={`${index}`}
                  style={{ textOverflow: "ellipsis" }}
                  data-test-id="dropdownItemID"
                >
                  {typeof option == "string" ? option : option["postcode"]},{option["admin_district"]},{option["parliamentary_constituency"]}
                </DropdownItem>
              ))}
            </DropdownList>
          </DropdownContent>
        )}
      </InputContainer>
    );

    const renderLocationDropdown = (inputId: any) => {
      return this.dropdown(handleInputChange, inputId)
    };


    const currentDate = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)

    const renderDateField = () => (
      <DatePicker
        selected={values[inputId] && this.handleFormatCorrectDate(typeof values[inputId] == "string" ? values[inputId] : new Date(values[inputId]))}
        onChange={handledateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/yyyy"
        name={inputId}
        data-testid={inputId}
        data-test-id="event-date"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onKeyDown={(e) => e.preventDefault()}
        className={`datepicker-custom ${this.requiredInputValidation(inputId) ? 'customInputError' : ''}`}
        minDate={currentDate}
        maxDate={new Date(currentDate.getFullYear() + 10, 11, 31)}
        excludeDates={[new Date(this.state.customForm.attributes.parent_inquiry_event_date)]}
      />

    );

    const renderCustomInput = (props: Object, eventName: string, timeZone: string) => {
      const handleErrorText = () => {
        if (eventName === "Event Start Time") {
          return this.state.timeValid.isStartTimeInvalid
            ? "Invalid time"
            : timeZone
        } else {
          return this.state.timeValid.isEndTimeInvalid
            ? "Invalid time"
            : timeZone;
        }
      };
      return (
        <Box style={timeInputStyle.box} className={`MuiBox-root ${!values[inputId] && this.requiredInputValidation(inputId) ? 'customInputError' : ''}`}>
          <input
            {...props}
            style={timeInputStyle.inputStyle}
            name={eventName}
          />
          <span style={timeInputStyle.timeZone}>
            {handleErrorText()}
          </span>
        </Box>
      );
    };

    const renderDateTimeField = (name: string) => {
      const timeZone = this.getTimezone();
      return (
        <Tooltip
          title={this.checkEventTimeValidation(name) ? "Start time is required to automatically populate the end time." : ""}
          placement="top">
          <Datetime
            data-test-id={name}
            value={values[inputId] ? values[inputId] : '00:00'}
            timeFormat="HH:mm"
            initialViewMode="time"
            dateFormat={false}
            onChange={(event) => {
              this.handleEventTime(name);
              this.saveEventTime(event, name, inputId, setFieldValue)
              this.handleTimeInput(event, name)
            }}
            inputProps={{
              placeholder: `HH:MM`,
              onInput: this.handleInput,
              pattern: "[0-9]{2}:[0-9]{2}",
              disabled: this.checkEventTimeValidation(name),
              readOnly: true
            }}
            timeConstraints={{ minutes: { step: 15, min: 0, max: 59 } }}
            renderInput={(props) => renderCustomInput(props, name, timeZone)}
            
          />
        </Tooltip>
      )
    };
    // istanbul ignore next
    const renderEventDaysField = () => (
      <Select data-testid={inputId} className={`multiselect-label ${this.requiredInputValidation(inputId) ? 'customInputError' : ''}`} name={inputId} value={values[inputId] === "More than 3" ? "1" : values[inputId]} style={{ backgroundColor: "#fff" }}
        onChange={(event) => {
          setFieldValue(inputId, event.target.value);
          this.saveData({ id: inputId, user_input: event.target.value });
          this.setState((prevState) => ({
            requiredInputFieldsValue: {
              ...prevState.requiredInputFieldsValue,
              [inputId]: event.target.value,
            },
          }));
        }}    
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center', },
          transformOrigin: { vertical: 'top', horizontal: 'center', },
          variant: 'menu',
          getContentAnchorEl: null,
          PaperProps: { style: { border: "3px solid black", borderTop: 0, margin: '0px', boxShadow: 'none', borderRadius: 0, marginTop: '-2px' }, },
        }}>
          {menuSelectedArray && menuSelectedArray.map((group:string, index: number) => (
          <MenuItem
            key={`${index}Id`}
            value={group}
            className="muliselect-custom-menu">
            {group}
          </MenuItem>
        ))}
      </Select>
    );

    return (
      <>
        <div style={{ marginBottom: "5px", fontSize: "16px", fontFamily: "CircularStdBold" }}>
          {baseDetails.attributes.input_field.name} {this.requiredInputValidation(inputId) && <span>*</span>}
        </div>
        {this.conditionalLocationFieldRender(baseDetails, inputId, renderLocationDropdown, renderSingleLocationField, renderTextFieldLocation)}
        {(baseDetails.attributes.input_field.name != "Location / Venue" && baseDetails.attributes.input_field.name != "Total Event Budget" && baseDetails.attributes.input_field.name != "Client Name" && baseDetails.attributes.input_field.name != "Company Name") && baseDetails.attributes.input_field.field_type === "text" && renderTextField()}
        {baseDetails.attributes.input_field.name == "Total Event Budget" && baseDetails.attributes.input_field.field_type === "text" && renderBudgetTextField()}
        {baseDetails.attributes.input_field.name == "Client Name" && baseDetails.attributes.input_field.field_type === "text" && this.state.customForm.attributes.is_previous && renderDisabledTextField()}
        {baseDetails.attributes.input_field.name == "Company Name" && baseDetails.attributes.input_field.field_type === "text" && this.state.customForm.attributes.is_previous && renderDisabledTextField()}
        {baseDetails.attributes.input_field.name == "Client Name" && baseDetails.attributes.input_field.field_type === "text" && !this.state.customForm.attributes.is_previous && renderTextField()}
        {baseDetails.attributes.input_field.name == "Company Name" && baseDetails.attributes.input_field.field_type === "text" && !this.state.customForm.attributes.is_previous && renderTextField()}
        {baseDetails.attributes.input_field.field_type === "calender_select" && renderDateField()}
        {baseDetails.attributes.input_field.field_type === "time" && renderDateTimeField(baseDetails.attributes.input_field.name)}
        {baseDetails.attributes.input_field.field_type === "multiple_options" && renderEventDaysField()}
      </>
    );
  }

  conditionalLocationFieldRender = (baseDetails: any, inputId: any, dropdown: any, single: any, field: any) => {
    if (baseDetails.attributes && baseDetails.attributes.input_field && baseDetails.attributes.input_field.name === "Location / Venue" && baseDetails.attributes &&  baseDetails.attributes.input_field && baseDetails.attributes.input_field.field_type === "text") {
      if (this.state.companyLocations.length > 1) {
        return dropdown(inputId);
      } else if (this.state.companyLocations.length === 1) {
        return single();
      } else {
        return field();
      }
    }
  }
  dropdown = (handleInputChange: any, inputId:any) => {
    const userInputValue = this.state.customForm.attributes &&
    this.state.customForm.attributes.base_service_detail && 
    this.state.customForm.attributes.base_service_detail.data &&
    this.state.customForm.attributes.base_service_detail.data.attributes &&
    this.state.customForm.attributes.base_service_detail.data.attributes.input_values && 
    this.state.customForm.attributes.base_service_detail.data.attributes.input_values.data;
    const locationInputValue = userInputValue?.find((item:any)=> item.attributes && item.attributes.input_field && item.attributes.input_field.name === "Location / Venue");
    const selectedLocation = this.state.customForm.attributes && this.state.customForm.attributes.is_previous ? locationInputValue.attributes && locationInputValue.attributes.user_input : this.state.eventDetails && this.state.eventDetails.location
    return (
      <InputContainer data-testid="input-renderLocationDropdown">
        <Select
          className={`multiselect-label select-dropdown ${this.requiredInputValidation(inputId) ? 'customInputError' : ''}`}
          data-testid="locationDropdown"
          value={selectedLocation}
          onChange={(e) => {
            this.handleLocationSelection(e);
            handleInputChange(e)
          }
          }
          style={{ backgroundColor: "#fff", width: "100%" }}
          renderValue={(selected: any) => {
            const displayText = selected.includes(selectedLocation) ? selectedLocation : "";
            return (
              <span style={{ fontFamily: "CircularStd", display: "inline-block", overflow: 'hidden', textOverflow: "ellipsis", width: '93%'}}>
                {displayText}
              </span>
            );
          }}
          MenuProps={menuProps}
        >
          {Array.isArray(this.state.companyLocations) && this.state.companyLocations.map((option, index) => {
            return (
              <CustomListItem value={option} key={index} data-testid={`${option}`} className={`muliselect-custom-menu wrap`}
                style={{alignItems: 'start', backgroundColor: this.setBackgroundColor(this.state.eventDetails.location || '', option) }}
              >
                <ListItemText primary={option} style={{ width: "fit-content" }} />
                <ListItemIcon>
                  <Checkbox checked={selectedLocation === option} />
                </ListItemIcon>
              </CustomListItem>
            );
          })}
        </Select>
        <LocationOnIcon style={{ color: 'black', position: 'absolute', top: '50%', right: '30px', transform: 'translateY(-50%)' }} />
      </InputContainer>
    );
  }
  singleLoc = (inputId: any) => {
    return (<TextField
      data-testid={inputId}
      className={`customInput ${this.requiredInputValidation(inputId) ? 'customInputError' : ''}`}
      fullWidth
      variant="standard"
      color="primary"
      InputProps={{
        style: { borderBottom: 'none' },
        disableUnderline: true,
      }}
      inputProps={{
        style: { backgroundColor: '#fff', outline: 'none' },
      }}
      value={this.state.companyLocations}
      name={inputId}
    />)
  }

  handleDaysCoverageChange = (event: any, inputId: any, values: any, setFieldValue: any, sub_category_name: any) => {
    const inputVal = event.target.value;
    setFieldValue(inputId, inputVal);
    this.saveData({ id: inputId, user_input: inputVal });
  };

  handleDropdownValueChange(event:any,inputAddonId:any,baseDetails:any,setFieldValue:any){
    setFieldValue(inputAddonId, event.target.value);
    let inputData = { id: inputAddonId, user_input: event.target.value };
    this.saveData(inputData);
  }
  
  renderDaysCoverageDropdown(baseDetails: any, values: any, setFieldValue: any) {
    const inputId = baseDetails.attributes.id.toString();
    const menuSelectedArray = baseDetails.attributes.input_field.options.split(',').map(function (item: any) {
      return item.trim();
    });
    const { sub_category_name } = this.state.customForm.attributes;
    let value: any;
    switch (true) {
      case sub_category_name?.includes("Half-Day"):
        value = 0.5;
        break;
      case sub_category_name?.includes("Full-Day"):
        value = 1;
        break;
      default:
        value = '';
        break;
    }

    return (
      <>
        <div style={{ marginBottom: "5px", fontSize: "16px", fontFamily: "CircularStdBold" }}>
          {baseDetails.attributes.input_field.name}
        </div>

        <Select
          data-test-id="daysCoverageDropdown"
          data-testid={`daysCoverageDropdown_${inputId}`} className="multiselect-label select-dropdown" name={`daysCoverageDropdown_${inputId}`} 
          value={values[inputId] ? values[inputId] : value} 
          style={webStyles.whiteBg}
          onChange={(event) => this.handleDaysCoverageChange(event, inputId, values, setFieldValue, sub_category_name)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'menu',
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                border: "3px solid black",
                borderTop: 0,
                margin: '0px',
                boxShadow: 'none',
                borderRadius: 0,
                marginTop: '-2px',
              },
            },
          }}

        >
          {menuSelectedArray.map((group: any, index: any) => (
            <MenuItem key={`${index}Id`} value={group} className="muliselect-custom-menu">
              {group}
            </MenuItem>
          ))}
        </Select>
        
      </>
    );
  }

  renderServiceRequireDropdown() {
    return (
      <div style={{ marginBottom: "5px", fontSize: "16px", fontFamily: "CircularStdBold" }}>
        {configJSON.serviceRequire}
      </div>
    );
  }

  renderAddonInputField(baseDetails: any, values: any, setFieldValue: any) {
    const inputAddonId = baseDetails.attributes.id.toString();
    return (
      <Grid item xs={6} sm={4} md={3} className="customInputFields" style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
        <>
          <div style={{ marginBottom: "5px", fontSize: "16px", fontFamily: "CircularStdBold" }}>
            {baseDetails.attributes.input_field.name}
          </div>
          {baseDetails.attributes.input_field.field_type === "text" &&
            <TextField
              className="customInput"
              variant="outlined"
              data-testid={inputAddonId}
              value={values[inputAddonId]}
              name={inputAddonId}
              fullWidth
              inputProps={{
                style: {
                  backgroundColor: "#fff"
                }
              }}
              onChange={(event) => {
                setFieldValue(inputAddonId, event.target.value);
                let inputData = { id: inputAddonId, user_input: event.target.value };
                this.saveData(inputData);
              }}
            />
          }
          {baseDetails.attributes.input_field.field_type === "calender_select" &&
            <TextField
              data-testid={inputAddonId}
              className="demo-simple-datepicker"
              id="date"
              type="date"
              defaultValue={new Date()}
              value={values[inputAddonId]}
              style={{ backgroundColor: "#fff" }}
              name={inputAddonId}
              onChange={(event) => {
                setFieldValue(inputAddonId, event.target.value);
                let inputData = { id: inputAddonId, user_input: event.target.value };
                this.saveData(inputData);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          {baseDetails.attributes.input_field.field_type === "multiple_options" &&
            <Select
              fullWidth
              name={inputAddonId}
              data-testid={"addonDropdown-id"}
              onChange={(event)=>this.handleDropdownValueChange(event,inputAddonId,baseDetails,setFieldValue)}
              className="multiselect-label select-dropdown"
              defaultValue=""
              style={{ backgroundColor: "#fff" }}
              value={values[inputAddonId]}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                variant: 'menu',
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    border: "3px solid black",
                    borderTop: 0,
                    margin: '0px',
                    boxShadow: 'none',
                    borderRadius: 0,
                    marginTop: '-2px'
                  },
                },
              }}
            >
              {baseDetails.attributes.input_field.options.split(',').map((group: any, menuIndex: number) => (
                <MenuItem key={`${menuIndex}Id`} value={group.trim()} className="muliselect-custom-menu">
                  {group}
                </MenuItem>
              ))}
            </Select>
          }
        </>
      </Grid>
    );
  }

  renderExtraAddOn(baseDetails: any, values: any, setFieldValue: any) {
    const extraAddonId = baseDetails.id;
    const menuMapArray = baseDetails.attributes.input_field.field_type === "multiple_options" ? baseDetails.attributes.input_field.options.split(',') : [];

    const handleInputChange = (event: any) => {
      setFieldValue(extraAddonId, event.target.value);
      let inputData = { id: extraAddonId, user_input: event.target.value };
      this.saveData(inputData);
    };

    return (
      <Grid item xs={6} sm={4} md={3} className="customInputFields" style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }} key={extraAddonId}>
        <div style={{ marginBottom: "5px", fontSize: "16px", fontFamily: "CircularStdBold" }}>
          {baseDetails.attributes.input_field.name}
        </div>
        {baseDetails.attributes.input_field.field_type === "calender_select" &&
          <TextField
            id="date"
            type="date"
            value={values[extraAddonId]}
            data-testid={extraAddonId}
            className="demo-simple-datepicker"
            defaultValue={new Date()}
            name={extraAddonId}
            style={{ backgroundColor: "#fff" }}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        }
        {baseDetails.attributes.input_field.field_type === "text" &&
          <TextField
            className="customInput"
            fullWidth
            data-testid={extraAddonId}
            variant="outlined"
            inputProps={{
              style: {
                backgroundColor: "#fff"
              }
            }}
            name={extraAddonId}
            onChange={handleInputChange}
          />
        }
        {baseDetails.attributes.input_field.field_type === "multiple_options" &&
          <>
            <Select fullWidth className="multiselect-label select-dropdown" defaultValue="" style={{ backgroundColor: "#fff" }} name={extraAddonId}
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                variant: 'menu',
                getContentAnchorEl: null,
                PaperProps: { style: { border: "3px solid black", borderTop: 0, margin: '0px', boxShadow: 'none', borderRadius: 0, marginTop: '-2px' }, },
              }}
              data-testid={extraAddonId} value={values[extraAddonId]} onChange={handleInputChange}
            >
              {menuMapArray.map((group: any, index: any) => (
                <MenuItem
                  key={`${index}Id`}
                  value={group.trim()}
                  className="muliselect-custom-menu">
                  {group}
                </MenuItem>
              ))}
            </Select>
          </>
        }
      </Grid>
    );
  }

  renderCustomFormSummary() {
    const prevCost = this.renderProvCost(this.state.customForm?.attributes?.cost_summery?.provisional_cost, this.state?.customForm?.attributes?.cost_summery?.extra_cost)
    if (this.state.customForm.attributes?.is_bespoke) {
      return (
        <ResponsiveAmountGrid item >
          <View style={{
            backgroundColor: '#DEDCFD', borderColor: '#000', borderWidth: 3,
            padding: 10, paddingTop: 5, paddingBottom: 5,
          }}>
            <View style={[styles.tableProvision, {
              borderBottomColor: '#CBD5E1',
              borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5
            }]}>
              <Text style={styles.fontSm}>
                Provisional Cost</Text>
              <Text style={styles.fontSm}>
                £{prevCost}</Text>
            </View>
            <View style={[styles.tableProvision, {
              borderBottomColor: '#CBD5E1', borderBottomWidth: 1,
              paddingBottom: 5, paddingTop: 5
            }]}>
              <Text
                style={styles.fontSm}>Inc. Add-ons
              </Text>
              <Text style=
                {styles.fontSm}>£{this.state.customForm.attributes.cost_summery.provisional_addon_cost}
              </Text>
            </View>
            <View style={
              [styles.tableProvision, {
                borderBottomColor: '#CBD5E1',
                borderBottomWidth: 1, paddingBottom: 5,
                paddingTop: 5
              }]}>
              <Text style={styles.fontSm}>
                Additional Services 
              </Text>
              <Text
                style={styles.fontSm}>
                £{this.state.customForm.attributes.cost_summery.additional_services_cost}</Text>
            </View>
            <View
              style={[styles.tableProvision, {
                borderBottomColor: '#CBD5E1',
                borderBottomWidth: 1,
                paddingBottom: 5, paddingTop: 5
              }]}>
              <Text
                style={styles.fontSm}>Inc. Add-ons</Text>
              <Text
                data-testid="additioncost"  style={styles.fontSm}>£{this.state.customForm.attributes.cost_summery.additional_addons_cost}</Text>
            </View>
            <View style={[styles.tableProvision,
            { paddingTop: 10 }]}>
              <Text style={{
                fontFamily: "CircularStdBold",
                fontSize: 16,
                color: '#000'
              }}>{configJSON.subTotal}</Text>
              <Text style={{
                fontFamily: "CircularStdBold",
                fontSize: 16,
                color: '#000'
              }}>£{this.state.customForm.attributes.cost_summery.sub_total}</Text>
            </View>
          </View>
        </ResponsiveAmountGrid>
      );
    }

    return (
      <ResponsiveAmountGrid item >
        <View style={{
          backgroundColor: '#DEDCFD', borderColor: '#000', borderWidth: 3, padding: 10, paddingTop: 5,
          paddingBottom: 5,
        }}>
          <View style={[styles.tableProvision, {
            borderBottomColor: '#CBD5E1', borderBottomWidth: 1,
            paddingBottom: 5, paddingTop: 5
          }]}>
            <Text style={styles.fontSm}>Provisional Cost
            </Text>
            <Text style={styles.fontSm}>
              £{prevCost}
            </Text>
          </View>
          <View style={[styles.tableProvision, {
            borderBottomColor: '#CBD5E1',
            borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5
          }]}>
            <Text style={styles.fontSm}>Inc. Add-ons
            </Text>
            <Text
              style={styles.fontSm}>£{this.state?.customForm?.attributes?.cost_summery?.provisional_addon_cost}
            </Text>
          </View>
          <View style={[styles.tableProvision, {
            borderBottomColor: '#CBD5E1',
            borderBottomWidth: 1,
            paddingBottom: 5,
            paddingTop: 5
          }]}>
            <Text style={styles.fontSm}>Additional Services
            </Text>
            <Text style={styles.fontSm}>
              £{this.state?.customForm?.attributes?.cost_summery?.additional_services_cost}
            </Text>
          </View>
          <View style={
            [styles.tableProvision, {
              borderBottomColor: '#CBD5E1',
              borderBottomWidth: 1, paddingBottom: 5,
              paddingTop: 5
            }]}>
            <Text style={styles.fontSm}>
              Inc. Add-ons
            </Text>
            <Text style={styles.fontSm}>
              £{this.state?.customForm?.attributes?.cost_summery?.additional_addons_cost}
            </Text>
          </View>
          <View style={[styles.tableProvision,
          { paddingTop: 10 }]}>
            <Text style={{
              fontFamily: "CircularStdBold", fontSize: 16,
              color: '#000'
            }}>
              {configJSON.subTotal}</Text>
            <Text style={
              {
                fontFamily: "CircularStdBold", fontSize: 16,
                color: '#000'
              }}>
              £{this.state?.customForm?.attributes?.cost_summery?.sub_total}
            </Text>
          </View>
        </View>
      </ResponsiveAmountGrid>
    );
  }
  renderCalendarType() {
    const { showOption } = this.state
    return (
      <Select
        data-testid="calendarType" className="multiselect-label icalendar-select"
        placeholder="Add to My Calendar"
        value={this.state.calendarType} onChange={(event) => this.handleCalendarTypeChange(event)}
        style={{ backgroundColor: '#fff', color: '#000', fontFamily: 'CircularStd', width: '100%', maxWidth: '250px', height: 42 }}
        inputProps={{
          style: {
            color: "#000"
          }
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom', horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top', horizontal: 'center',
          },
          variant: 'menu',
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              border: '3px solid black', borderTop: 0, margin: '0px',
              boxShadow: 'none', borderRadius: 0,
            },
          },
        }}>
        <MenuItem
          value="apple"
          className="muliselect-custom-menu"><img
            src={appleicon} alt="apple calender"
            style={{
              marginRight: '5px', height: "14px",
              paddingBottom: "4px"
            }} />Apple Calendar</MenuItem>
        <MenuItem value="google"
          className="muliselect-custom-menu"><img
            src={googlecalicon} alt="google calender"
            style={{
              marginRight: '5px', height: "14px",
              paddingBottom: "4px"
            }} />Google Calendar</MenuItem>
        <MenuItem value="outlook"
          className="muliselect-custom-menu"><img
            src={windowscalicon} alt="outlook calender"
            style={{
              marginRight: '5px', height: "14px",
              paddingBottom: "4px"
            }} />Outlook</MenuItem>
        <MenuItem value="yahoo"
          className="muliselect-custom-menu"><img
            src={yahooIcon} alt="yahoo icon"
            style={{
              marginRight: '5px', height: "14px",
              paddingBottom: "4px"
            }} />Yahoo</MenuItem>
        <MenuItem value="download"
          className="muliselect-custom-menu">
          <GetAppIcon style={{ height: "16px", marginLeft: "-6px" }} />Download (.ics)</MenuItem>
        <MenuItem value="calendarIcon"
          className="muliselect-custom-menu" style={{ display: showOption ? 'block' : 'none' }}><img
            src={calendaricon} alt="yahoo icon"
            style={{
              marginRight: '5px', height: "14px",
              paddingBottom: "4px"
            }} />Add to My Calendar</MenuItem>

      </Select>
    )
  }

  renderCalendarButton() {
    return (<>
      {(this.state.calendarType === 'download' || this.state.calendarType === 'apple') ? (
        <ICalendarLink className="btn-sm w-150" event={this.state.eventDetails}>Continue</ICalendarLink>) : (
        "Continue")}
    </>)
  }

  renderCustomFormPopupPriceDetails() {
    const prevCost = this.renderProvCost(this.state.customForm?.attributes?.cost_summery?.provisional_cost, this.state?.customForm?.attributes?.cost_summery?.extra_cost)
    if (this.state?.customForm?.attributes?.is_bespoke) {
      return (
        <View
          style={{ backgroundColor: '#DEDCFD', borderColor: '#000', borderWidth: 3, padding: 20, marginRight: 20, paddingTop: 10, paddingBottom: 10, width: 450, maxWidth: 'calc(100% - 20px)' }}>
          <Text
            style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 14, paddingBottom: 20 }}>
            {this.state?.customForm?.attributes && this.state?.customForm?.attributes?.service_name}
          </Text>
          <View
            style={[styles.tableProvision, { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
            <Text
              style={styles.fontSm}>Provisional Cost</Text>
            <Text
              style={styles.fontSm}>£{prevCost}</Text>
          </View>
          <View
            style={[styles.tableProvision, { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
            <Text
              style={styles.fontSm}>Inc. Add-ons</Text>
            <Text
              style={styles.fontSm}>£{this.state.customForm.attributes.cost_summery.provisional_addon_cost}</Text>
          </View>
          <View
            style={[styles.tableProvision, { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
            <Text
              style={styles.fontSm}>Additional Services</Text>
            <Text
              style={styles.fontSm}>£{this.state.customForm.attributes.cost_summery.additional_services_cost}</Text>
          </View>
          <View
            style={[styles.tableProvision, { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
            <Text
              style={styles.fontSm}>Inc. Add-ons</Text>
            <Text
              style={styles.fontSm}>£{this.state.customForm.attributes.cost_summery.additional_addons_cost}</Text>
          </View>
          <View
            style={[styles.tableProvision, { paddingTop: 10 }]}>
            <Text
              style={{ fontFamily: "CircularStdBold", fontSize: 16, color: '#000' }}>{configJSON.subTotal}</Text>
            <Text
              style={rbsCostStyle}>£{this.state.customForm.attributes.cost_summery.sub_total}</Text>
          </View>
        </View>
      );
    }

    return (
      <View
        style={{
          backgroundColor: '#DEDCFD', borderColor: '#000', borderWidth: 3, marginRight: 20, padding: 20, paddingTop: 10, paddingBottom: 10, width: 450, maxWidth: 'calc(100% - 20px)'
        }}>
        <Text
          style={{
            fontFamily: "CircularStdBold", color: "#000000", fontSize: 14, paddingBottom: 20
          }}>
          {this.state?.customForm?.attributes && this.state?.customForm?.attributes?.service_name}
        </Text>
        <View
          style={
            [styles.tableProvision, { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text
            style={
              styles.fontSm}>Provisional Cost</Text>
          <Text
            style={
              styles.fontSm}>£{prevCost}</Text>
        </View>
        <View
          style={
            [styles.tableProvision, { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text
            style={
              styles.fontSm}>Inc. Add-ons</Text>
          <Text
            style={
              styles.fontSm}>£{this.state?.customForm?.attributes?.cost_summery?.provisional_addon_cost}</Text>
        </View>
        <View
          style={[
            styles.tableProvision, { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text
            style={
              styles.fontSm}>Additional Services</Text>
          <Text
            style={
              styles.fontSm}>£{this.state?.customForm?.attributes?.cost_summery?.additional_services_cost}</Text>
        </View>
        <View
          style={[
            styles.tableProvision, { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text style={
            styles.fontSm}>Inc. Add-ons</Text>
          <Text style={
            styles.fontSm}>£{this.state?.customForm?.attributes?.cost_summery?.additional_addons_cost}</Text>
        </View>
        <View style={[
          styles.tableProvision, { paddingTop: 10 }]}>
          <Text style={{
            fontFamily: "CircularStdBold", fontSize: 16, color: '#000'
          }}>{configJSON.subTotal}</Text>
          <Text style={{
            fontFamily: "CircularStdBold", fontSize: 16, color: '#000'
          }}>£{this.state?.customForm?.attributes?.cost_summery?.sub_total}</Text>
        </View>
      </View>
    );
  }


  renderServiceTitle() {
    if (!this.state.customForm.attributes) {
      return null;
    }

    const { service_name, sub_category_name } = this.state.customForm.attributes;

    if (service_name == "Bespoke Request") {
      return (
        <Text style={{ fontWeight: '600', fontFamily: "CircularStdBold", fontSize: 30 }}>
          {service_name}
        </Text>
      );
    }

    return (
      <Text style={{ fontWeight: '600', fontFamily: "CircularStdBold", fontSize: 30 }}>
        {service_name} | {sub_category_name}
      </Text>
    );
  }

  tooltipFunction(name: any, value: any) {
    return (
      name == "Event Date" && value)
  }

  getTooltipProps = (input:any) => {
    const userInput = input.attributes.user_input;
    return {
        title: userInput.length > 28 ? userInput : "",
        disableHoverListener: userInput.length <= 28,
    };
};

  renderDownloadFileButton = () => {
    return (
      this.state.customForm.attributes.files.length > 0 ? (
        <AttachmentDiv>
          {this.state.customForm.attributes.files.map((file: any) => (
            <div className="item">
              <p style={{ fontFamily: "CircularStd", color: "#000000", margin: 0, fontSize: 16, width: "80%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>{this.getFileName(file.name)}</p>
              {<p style={{ cursor: "pointer", margin: 0 }} onClick={() => this.downloadFile(file.name, file.url)} data-test-id="downloadTest"><img src={downloadImage} style={{ height: "24px", width: "24px" }} /></p>}
            </div>
          ))}
        </AttachmentDiv>
      ) : (
        <Text style={{ fontFamily: "CircularStd", color: "#000000", fontSize: 14 }}>No Files Added</Text>
      )
    )
  }

  renderAttachmentsFunction = () => {
    return (
      this.state.customForm?.attributes && this.state.customForm?.attributes?.files && (
        <>
          <View>
            <Text style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 30, lineHeight: 62 }}>Attachments</Text>
            <div>
              {this.state.customForm.attributes.files.length > 0 ? (
                <AttachmentDiv>
                  {this.state.customForm.attributes.files.map((file: any) => (
                    <div className="item">
                      <p style={{ fontFamily: "CircularStd", color: "#000000", fontSize: 16, width: "80%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", margin: 0 }}>{this.getFileName(file.name)}</p>
                      {<p style={{ cursor: "pointer", margin: 0 }} onClick={() => this.downloadFile(file.name, file.url)} data-test-id="downloadTest"><img src={downloadImage} style={{ height: "24px", width: "24px" }} /></p>}
                    </div>
                  ))}
                </AttachmentDiv>
              ) : (
                <Text style={{
                  fontFamily: "CircularStd",
                  color: "#000000", fontSize: 14
                }}>
                  No Files Added</Text>
              )}
            </div>
          </View>
        </>
      )
    )
  }

  isBespokeClientAdmin = () => {
    const isBespoke = this.state.customForm && this.state.customForm.attributes && this.state.customForm.attributes.base_service_detail?.data?.attributes?.service_name === "Bespoke Request"
    return (
      !this.state.customForm?.attributes?.is_previous && this.state.isBespokeRequest || this.state.customForm?.attributes?.is_previous && this.state.draftEnquiryResponse.lf_admin_approval_required  || isBespoke ?
        <>
          <Typography className="heading" variant="h5" component="h2" style={{ fontSize: 30, color: "#000", marginBottom: "0", fontFamily: 'CircularStdBold', backgroundColor: 'transparent', lineHeight: '44px', textAlign: 'center' }}>Successfully submitted to London Filmed</Typography>
          <div className="text" style={{ color: "#5A5A5A", lineHeight: '28px', fontFamily: 'CircularStd', fontSize: 16, textAlign: 'center', maxWidth: 440, }}>Thanks for submitting your enquiry. It has been successfully submitted to your London Filmed Account Manager for review.</div>
          <div className="text" style={{ color: "#5A5A5A", marginTop: '10px', lineHeight: '28px', fontFamily: 'CircularStd', textAlign: 'center', fontSize: 16, }}>Your Account Manager will be in touch shortly with your quote or to contact you for more information.</div>
        </> :
        <>
  <Typography className="heading" variant="h5" component="h2" style={{ fontSize: 30, color: "#000", marginBottom: "0", fontFamily: 'CircularStdBold', backgroundColor: 'transparent', lineHeight: '44px', textAlign: 'center' }}>Enquiry Approved</Typography>
          <div className="text" style={{ color: "#5A5A5A", marginTop: '10px', lineHeight: '28px', fontFamily: 'CircularStd', textAlign: 'center', fontSize: 16, }}>Your enquiry has been successfully approved and sent to London Filmed.</div>
        </>
    )
  }

  isBespokeClientUser = () => {
        const isBespoke = this.state.customForm && this.state.customForm.attributes && this.state.customForm.attributes?.base_service_detail?.data?.attributes?.service_name === "Bespoke Request"
    return (
      !this.state.customForm?.attributes?.is_previous && this.state.isBespokeRequest || this.state.customForm?.attributes?.is_previous && this.state.draftEnquiryResponse.lf_admin_approval_required  || isBespoke?
        <>
          <Typography className="heading" variant="h5" component="h2" style={{ fontSize: 30, color: "#000", marginBottom: "0", fontFamily: 'CircularStdBold', backgroundColor: 'transparent', lineHeight: '44px', textAlign: 'center' }}>Successfully Submitted to London Filmed</Typography>
          <div className="text" style={{ color: "#5A5A5A", fontFamily: 'CircularStd', fontSize: 16, lineHeight: '28px', maxWidth: 440, textAlign: 'center' }}>Thanks for submitting your enquiry. It has been successfully submitted to your London Filmed Account Manager for review.</div>
          <div className="text" style={{ color: "#5A5A5A", fontFamily: 'CircularStd', fontSize: 16, marginTop: '10px', lineHeight: '28px', maxWidth: 440, textAlign: 'center' }}>Your Account Manager will be in touch shortly with your quote or to contact you for more information.</div>
        </> : parseFloat(this.state?.customForm?.attributes?.cost_summery?.sub_total.replace(/,/g, '')) < this.state?.customForm?.attributes?.auto_approval_limit  ? 
<>
<Typography className="heading" variant="h5" component="h2" style={{ fontSize: 30, color: "#000", marginBottom: "0", fontFamily: 'CircularStdBold', backgroundColor: 'transparent', lineHeight: '44px', textAlign: 'center' }}>Enquiry Approved</Typography>
          <div className="text" style={{ color: "#5A5A5A", marginTop: '10px', lineHeight: '28px', fontFamily: 'CircularStd', textAlign: 'center', fontSize: 16, }}>Your enquiry has been successfully approved and sent to London Filmed.</div>
        </>  :
      <> 
         <Typography className="heading" variant="h5" component="h2" style={{ fontSize: 30, color: "#000", marginBottom: "0", fontFamily: 'CircularStdBold', backgroundColor: 'transparent', lineHeight: '44px', textAlign: 'center' }}>Enquiry Submitted</Typography>
          <div className="text" style={{ color: "#5A5A5A", marginTop: '10px', lineHeight: '28px', fontFamily: 'CircularStd', textAlign: 'center', fontSize: 16, }}>Your enquiry has been submitted to London Filmed, your Account Manager will be in touch shortly</div>
        </> 

    )
  }

  renderPackageSelectDropdown = () => {
    return (
      this.state.packageSlectedList.length > 0 &&
      <>

        <Select
          className="multiselect-label select-dropdown"
          multiple
          data-testid={"addonCategoryDropdown"}
          value={this.state.packageSelectedItem as any}
          onChange={(e) => this.handlePackageSelection(e)}
          onOpen={() => this.handleDropdownToggle(true)}
          onClose={() => this.handleDropdownToggle(false)}
          open={this.state.isDropdownOpen}
          style={{ backgroundColor: "#fff" }}
          renderValue={(selected: any) => {
            const selectedTitles = (selected as any[])
              .map((roleId) => {
                return this.state.packageSlectedList?.find(e => e.id === roleId)?.title
              })
              .filter(Boolean);

            const totalCharacters = selectedTitles.join(",");

            let displayText;
            if (totalCharacters.length > 35) {
              displayText = selectedTitles.join(", ").slice(0, 35) + "...";
            } else {
              displayText = selectedTitles.join(", ");
            }

            return (
              <Tooltip title={selectedTitles.join(", ")} placement="top">
                <div className="packageSeletcedLabel">
                  <Typography style={{ fontFamily: "CircularStd", display: "inline-block" }}>
                    {displayText}
                  </Typography>
                </div>
              </Tooltip>
            );
          }}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            variant: 'menu',
            className: 'multiselect-popover-custom',
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                width: "auto",
                margin: '0px',
                border: "3px solid black",
                borderTop: 0,
                boxShadow: 'none',
                borderRadius: 0,
              },
            },
          }}
        >
          {this.state.packageSlectedList.map((packageName) => {
            const isDisabled = this.isPackageDisabled(packageName);
            return (
              <CustomListItem value={packageName.id} key={packageName.id} className={`muliselect-custom-menu ${isDisabled ? 'disabled-option' : ''}`}
                data-testid={packageName.title} onClick={(event) => {
                  if (isDisabled) {
                    this.handleDropdownToggle(false); 
                  }
                }}
              >
                <ListItemText style={{ width: "fit-content" }} primary={packageName.title} />
                <ListItemIcon>
                <Checkbox 
                  checked={!isDisabled && Array.isArray(this.state.packageSelectedItem) && this.state.packageSelectedItem.indexOf(packageName.id) > -1} 
                />
                </ListItemIcon>
              </CustomListItem>
            )
          })
          }
        </Select>

      </>

    )
  }

  renderInfoMessage = () => {
    const { infoMessage, isAVSelected } = this.state;
    return (
      <InfoPaper data-testid="infoMessageWrapper"
        style={this.setInfoPaperStyle()}
      >
        <div style={{ display: 'flex' }}>
          <InfoOutlinedIcon className="infoIcon" />
          <Typography className="infoText" data-testid="infoMessageAdditional">
            {infoMessage.infoText}
          </Typography>
        </div>

        <Button
          onClick={this.receiveAVPacks}
          className="infoButton"
          style={this.setButtonStyle()}
          data-testid="makeChanges"
        >
          {infoMessage.buttonText}
        </Button>
      </InfoPaper>

    )

  };

  renderInfoMessageWrapper = () => {
    const { isInfoMessage } = this.state;

    if (isInfoMessage) {
      return this.renderInfoMessage();
    }
    return null;
  }

  renderDownloadButton = (view: boolean, urlLink: string) => {
    if (view) {
      return <a data-test-id="downloadID" href={urlLink} download>
        <Button
          type="submit"
          className="btn-sm w-195"
        >Download
        </Button>
      </a>
    }
  }

renderAmountwithLimitState (){
  const {customForm} = this.state;
  return customForm && customForm.attributes && customForm.attributes.auto_approval_limit === 0 ? (
    <Button
    data-testid = "btn-amount-with-limit"
    className="btn-sm w-195"
    onClick={this.submitForm}
    style={{ backgroundColor: !this.state.is_service ? '#ccc' : "" }}
    disabled={!this.state.is_service}
  >
    Submit
  </Button>
  ) : (
    this.renderApproveorSubmitwithLimit()
  )
}

renderApproveorSubmitwithLimit (){
  const {customForm} = this.state;
  if(customForm){
  const customVal = parseFloat(customForm.attributes?.cost_summery?.sub_total.replace(/,/g, ''))
  const approvalLimit = parseFloat(customForm.attributes?.auto_approval_limit);
  return  (customVal < approvalLimit) ? (
      <Button
        className="btn-sm w-195"
        onClick={this.approveForm}
        style={{ backgroundColor: !this.state.is_service ? '#ccc' : "" }}
        disabled={!this.state.is_service}
      >
        Approve
      </Button>
    )
  : (
      <>
        <Button
          className="btn-sm w-195"
          onClick={this.submitForm}
          style={{ backgroundColor: !this.state.is_service ? '#ccc' : "" }}
          disabled={!this.state.is_service}
        >
          Submit
        </Button>
      </>
  )
  }
  }
renderApproveorSubmitButtonUser () {
  const isBespoke = this.state.customForm && this.state.customForm.attributes && this.state.customForm.attributes?.base_service_detail?.data?.attributes.service_name === "Bespoke Request"


   return this.state.groupByAdditionalAddon.length <= 1 || isBespoke
      ? (
       this.renderAmountwithLimitState()
        )
      : (
          <Button
            className="btn-sm w-195"
            onClick={this.submitForm}
            style={{ backgroundColor: !this.state.is_service ? '#ccc' : "" }}
            disabled={!this.state.is_service}
          >
            Submit
          </Button>
        )
}
renderApproveorSubmitButtonAdmin (){
  const isBespoke = this.state.customForm && this.state.customForm.attributes && this.state.customForm.attributes.base_service_detail?.data?.attributes?.service_name === "Bespoke Request"

   return this.state.groupByAdditionalAddon.length > 1 || isBespoke ?   <Button
    className="btn-sm w-195"
    onClick={this.submitForm}
    style={{ backgroundColor: !this.state.is_service ? '#ccc' : '' }}
  >Submit</Button> :
  <Button
  className="btn-sm w-195"
  onClick={this.approveForm}
  data-test-id='approve-admin-enquiry'
  style={{ backgroundColor: !this.state.is_service ? '#ccc' : '' }}
>Approve</Button>
}

  renderAdditionalSubCategory() {
    const { additionalServicesSubCategories } = this.state;

    return (
      <>
        {additionalServicesSubCategories?.map((additionalPackage) => {
          const subCategoryOptions = additionalPackage.subCategories
          if (subCategoryOptions.length === 0) return null;
          return (
            <Grid item xs={6} sm={4} md={3} className="customInputFields" style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
              <>
                <Grid item xs={12} sm={10} md={8} className="customInputFields">
                  <div style={{ marginBottom: "5px", fontSize: "16px", fontFamily: "CircularStdBold" }}>
                    Service:
                  </div>
                  <div style={{ marginBottom: "24px", fontSize: "14px", fontWeight: 450 }}>
                    {additionalPackage.title}
                  </div>
                </Grid>

                <div style={{ marginBottom: "5px", fontSize: "16px", fontFamily: "CircularStdBold" }}>
                  Packages:
                </div>
                <Select
                  key={additionalPackage.id}
                  className="multiselect-label select-dropdown"
                  onChange={(event) => this.handleSubCatChange(event, additionalPackage.id)}
                  data-testid={`additionalSubCategories-${additionalPackage.id}`}
                  value={additionalPackage.selectedSubCat}
                  style={{ backgroundColor: "#fff" }}
                  renderValue={(selected: any) => (
                    <span style={{ fontFamily: 'CircularStd', fontWeight: 'bold' }}>
                      {selected}
                    </span>
                  )}
                  MenuProps={{
                    anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    transformOrigin: { vertical: "top", horizontal: "center" },
                    variant: "menu",
                    className: "multiselect-popover-custom",
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        width: "auto",
                        margin: "0px",
                        border: "3px solid black",
                        borderTop: 0,
                        boxShadow: "none",
                        borderRadius: 0,
                      },
                    },
                  }}
                >
                  {subCategoryOptions.map((subCategory: string) => {
                    return (
                      <MenuItem
                        value={subCategory}
                        key={subCategory}
                        className={`muliselect-custom-menu`}
                        style={{ backgroundColor: this.setBackgroundColor(additionalPackage.selectedSubCat, subCategory) }}
                      >
                        <ListItemText style={{ width: "fit-content" }} primary={subCategory} />
                        <ListItemIcon>
                          <Checkbox
                            checked={additionalPackage.selectedSubCat === subCategory}
                          />
                        </ListItemIcon>
                      </MenuItem>
                    )
                  })}
                </Select>
              </>
            </Grid>

          )
        })}
        </>
    );
  }
  renderAdditionalSubCatAccordion() {
    const { additionalServicesSubCategories } = this.state;
    if (additionalServicesSubCategories.length > 0) {
      return (
        <Accordion className="custom-accordion" defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-accordion"
            id="panel-accordion"
          >
            <Text style={styles.accordionTittle}>{configJSON.additionalSubCategories}</Text>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} style={{ alignItems: "center" }}>

              {this.renderAdditionalSubCategory()}

            </Grid>
          </AccordionDetails>
        </Accordion>

      )
    }
  }

  renderMeetingButton() {
    const { isMoreStageOfCoverage } = this.state;
  
    if (isMoreStageOfCoverage) {
      const isValidLink = this.isValidMeetLink();
      const buttonStyle = isValidLink
        ? { backgroundColor: "#00BFBB" }
        : { backgroundColor: "#5A5A5A" };
      const textStyle = isValidLink
        ? { background: "#fff" }
        : { backgroundColor: "#D9D9D9" };
  
      return (
        <View>
          <Tooltip
            title={isValidLink ? "" : "Invalid Meeting link"}
            placement="top"
          >
            <div className="buttonWrapper w-150" style={{ width: 199 }}>
              <div className="buttonOutline" style={buttonStyle}></div>
              <Button
                data-test-id="testConfirmBtn"
                type="submit"
                className="btn-sm w-150"
                style={textStyle}
                disabled={!isValidLink}
                onClick={this.meetLink}
              >
                Schedule Meeting
              </Button>
            </div>
          </Tooltip>
        </View>
      );
    } else {
      return (
        <View>
          <div className="buttonWrapper w-150 btn-blue" style={{ width: 150 }}>
            <div className="buttonOutline"></div>
            <Button
              data-test-id="confirmBtn"
              type="submit"
              className="btn-sm w-150"
              onClick={this.changeEnquiriesClick}
            >
              Proceed
            </Button>
          </div>
        </View>
      );
    }
  }

  renderCompanySuspendedDialog = () => {
    return  <Dialog
              open={this.state.isSuspended}
              onClose={this.handleGoToLoginPage}
              aria-labelledby="suspended-account-dialoge"
              aria-describedby="suspended-account-dialoge-description"
              data-test-id="suspendedModal">
              <Box style={accountSuspendedModal.container} >
                <IconButton
                  disableTouchRipple
                  disableRipple
                  disableFocusRipple
                  data-test-id="suspendedModalCloseBtn"
                  onClick={this.handleGoToLoginPage}
                  style={accountSuspendedModal.crossMarkCloseBtn}>
                  <img src={imgClose}/>
                </IconButton>
                <Box style={accountSuspendedModal.modalContentWrapper}>
                  <Box style={accountSuspendedModal.crossMark}>
                    <ClearIcon style={accountSuspendedModal.crossMarkIcon} />
                  </Box>
                  <Typography style={accountSuspendedModal.suspendedTitle}>
                    {this.state.suspendedTitle}
                  </Typography>
                  <DialogContent>
                    <DialogContentText
                      style={accountSuspendedModal.suspendedMessage}
                      id="suspended-account-dialoge-description"
                      data-test-id="suspendedMessageID">
                      {this.state.suspendedMessage}
                    </DialogContentText>
                  </DialogContent>
                </Box>
              </Box>
            </Dialog>
  }

  renderLoading() {
    return (
     <><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: "100%", backgroundColor: 'transparent' }}>
        <img src={loadingImg} style={{ height: "50%", width: "25%" }} />
      </div>
      {this.renderCompanySuspendedDialog()}
      </>
    );
  }

  getReviewedTime(name: string, value: any): unknown {
    if (name === "Event Start Time" || name === "Event End Time") {
      return this.getTimeString(value);
    }
    return value;
  }

  renderAccordionForAddons(extraAddon:any, index:any, values:any, setFieldValue:any) {
    const hasInputFields = extraAddon.attributes && extraAddon.attributes.input_values && extraAddon.attributes.input_values.data && extraAddon.attributes.input_values.data.length > 0;
    return (
      <div key={index}>
      {hasInputFields && (
        <Accordion className="custom-accordion" defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-header-${index}`}
            id={`panel-header-${index}`}
          >
            <Text style={styles.accordionTittle}>
              {extraAddon.attributes.service_name}: {configJSON.default}
            </Text>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={0} style={{ flex: 2, flexDirection: "row", justifyContent: 'space-between' }}>
                                      {Object.entries(this.state.addonCoverage).map(function ([serviceName, categories]: [string, any]) {
                                        if (serviceName === extraAddon.attributes.service_name) {
                                          return Object.entries(categories).map(function ([category, featureData]: [string, any]) {
                                            return (
                                              <Grid item key={`${serviceName}-${category}`} className="customInputFields" md={3} xs={6}>
                                                <Text style={[styles.fontm, { textTransform: "capitalize" }]}>{category}</Text>
                                                <ul className="custom-list">
                                                  {featureData.map((data: any, index: number) => {
                                                    return <li key={`${index}-${data}`}>{data}</li>
                                                  })}
                                                </ul>
                                              </Grid>
                                            );
                                          });
                                        }
                                        return null;
                                      })}

                                    </Grid>
          </AccordionDetails>
        </Accordion>
      )}
{hasInputFields && (
  <Accordion className="custom-accordion" defaultExpanded={true} key={`${index}Id`}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel1-baserservice-${index}`}
                                    id={`panel-baserservice-${index}`}
                                  >
                                    <Text style={[styles.accordionTittle, { textTransform: "none" }]}>
                                      Add-ons: {extraAddon.attributes.service_name}
                                    </Text>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing={3}>
                                      {extraAddon.attributes.input_values.data.map((baseDetails: any, index: number) =>
                                        this.renderExtraAddOn(baseDetails, values, setFieldValue)
                                      )}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              )}
      </div>
    );
  }
  
  handleContinueToReview = () => {
    this.setState({continueToReview : true})
    let isPreviousEventDateEqualCurrentDate = false;
    const previousEventDate = moment(this.state.customForm.attributes?.parent_inquiry_event_date).format("DD/MM/YYYY")
    const currentEventDate = this.state.customForm.attributes?.event_date
    isPreviousEventDateEqualCurrentDate = currentEventDate === previousEventDate;
    const allSubCatsSelected = this.state.additionalServicesSubCategories.every((packageItem) => packageItem.selectedSubCat !== null);
    if (!allSubCatsSelected && this.state.packageSelectedItem.length) {
      this.setState({ openDialogName: "ErrorForPackages", errorMessage : "Please select additional service subcategory before proceeding"});
    } else if (this.state.timeValid.isEndTimeInvalid || this.state.timeValid.isStartTimeInvalid) {
      this.setState({ openDialogName: "ErrorForPackages", errorMessage : "Please provide valid time"});
    } 
      else if(this.state.customForm.attributes?.is_previous && isPreviousEventDateEqualCurrentDate === true) {
        this.setState({ openDialogName: "ErrorForPackages", errorMessage : "The enquiry must be unique. Please update the 'Event Date' field to proceed with submission."});
      }
    else {
      this.goToDraftEnquire();
    }
  }
  

  handleTextLengthCount = () => {
    return this.state.initialValueField?.note?.length ?? 0
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLoading,initialValueField } = this.state;

   if (isLoading) {
    return this.renderLoading();
  }
    const values = initialValueField
    const setFieldValue = this.handleInputChange
    const reviewEventStartTime = (name: any, value: any): unknown => {
      return this.getReviewedTime(name, value);
    };

    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        <AppHeader
          logoUrl={imgLogo}
          homeText="Home"
          createImageUrl={createImage}
          helpCenterText="Help Centre"
          userUrl={imgUser}
          settingsIcon
          helpCenterNotificationUrl={imgNotification}
          onPressHome={() => this.goToHome()}
          onClickViewProfile={() => this.goToProfileCustom()}
          onClickHelpCenter={() => this.goToHelpCenterCustom()}
          onClickViewEnquiries={() => this.goToEnquiryCustom()}
          onClickSettings={() => this.goToSettingsCustom()}
          onClickQuote={() => this.goToQuoteCustom()}
          data-test-id="testAppHeader"
          quoteUrl={group_plus}
        />
        <View
          style={{
            paddingTop: 30,
            paddingBottom: 40,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: '#F7F6FF'
          }}
        >
          <EventDetailsContainer>
            <EventInfoContainer>
              <Container className="home" disableGutters maxWidth={false}>
                <Text style={{ color: '#5A5A5A', marginBottom: 40, fontFamily: "CircularStd, sans-serif", }} onPress={this.goToHome} data-testid="goToHome">
                  Home
                </Text>
                <img src={imgRightArrow} width={10} style={{ marginLeft: 10, marginRight: 10 }} alt="imagRightArrow" />
                <Text style={{ color: '#5A5A5A', marginBottom: 40, fontFamily: "CircularStd, sans-serif", }} onPress={this.goToService}>
                  {configJSON.sType}
                </Text>
                <img src={imgRightArrow} width={10} style={{ marginLeft: 10, marginRight: 10 }} alt="imagRightArrow" />
                <Text style={{ color: '#594FF5', fontSize: 14, marginBottom: 40, fontFamily: "CircularStdBold, sans-serif" }}>
                  Package Type
                </Text>
              </Container>
              <Box className="eventTitle">
                {this.renderServiceTitle()}
                <View>
                  <Text style={{ color: '#5A5A5A', fontFamily: "CircularStd, sans-serif", lineHeight: 30 }}>{configJSON.fillInformation}</Text>
                </View>
              </Box>
            </EventInfoContainer>

            {this.renderCustomFormSummary()}

          </EventDetailsContainer>
          {Object.keys(this.state.customForm).length > 0 &&
            <CustomFormContainer>
              <Grid container spacing={2} style={{ alignItems: "center" }} >

                <Grid item xs={12}>
                  {Object.keys(this.state.groupByCoverage).length > 0 &&
                    <Accordion className="custom-accordion" defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-header"
                        id="panel-header"
                      >
                        <Text style={styles.accordionTittle}>{configJSON.default}</Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={0} >
                          {Object.entries(this.state.groupByCoverage).map(function ([key, featureData]: [string, any]) {
                            return (
                              <Grid item key={key} md={3} xs={6}>
                                <Text style={[styles.fontm, { textTransform: "capitalize" }]}>{key.replace(/_/g, '-')}</Text>
                                <ul className="custom-list">
                                  {featureData.map((data: any, index: number) => {
                                    return <li key={`${index}title`}>{data.title}</li>
                                  }
                                  )}
                                </ul>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  }
                </Grid>
                    <form noValidate autoComplete="off" style={webStyles.form} data-test-id="requestform">
                      <div>
                        {this.state.groupByRequiredInformation &&
                          <Accordion className="custom-accordion" defaultExpanded={true} >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-requiredInfo"
                              id="panel-requiredInfo"
                            >
                              <Text style={styles.accordionTittle}>{configJSON.requiredinfo}  </Text>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={3}>
                                {this.state.groupByRequiredInformation.map((baseDetails: any, index: any) => (
                                  <Grid item xs={6} sm={4} md={3} className="customInputFields" style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }} key={index}>
                                    {this.renderInputField(baseDetails, values, setFieldValue)}
                                  </Grid>
                                ))}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        }

                        <Accordion className="custom-accordion" defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-accordion"
                            id="panel-accordion"
                          >
                            <Text style={styles.accordionTittle}>{configJSON.services}</Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={4} style={{ alignItems: "center" }}>
                              {this.state?.groupByServices?.map((baseDetails: any, index: number) => (
                                <Grid item xs={6} sm={4} md={3} className="customInputFields" style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }} key={index}>
                                  {this.renderDaysCoverageDropdown(baseDetails, values, setFieldValue)}
                                </Grid>
                              ))}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        {this.state.groupByAddons &&
                          <Accordion className="custom-accordion" defaultExpanded={true}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-requiredInfo"
                              id="panel-requiredInfo"
                            >
                              <Text style={[styles.accordionTittle, { textTransform: "none" }]}>Add-ons: {this.state.customForm.attributes.service_name}</Text>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={3}>
                                {this.state.groupByAddons.map((baseDetails: any, index: number) => (
                                  <React.Fragment key={baseDetails.attributes.id}>
                                    {this.renderAddonInputField(baseDetails, values, setFieldValue)}
                                  </React.Fragment>
                                ))}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        }
                        <Accordion className="custom-accordion" defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-accordion"
                            id="panel-accordion"
                          >
                            <Text style={styles.accordionTittle}>{configJSON.additionalServices}</Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={4} style={{ alignItems: "center", padding: '12px' }}>

                              {this.renderServiceRequireDropdown()}
                              <Grid container spacing={3} style={{ alignItems: "start" }}>
                                <Grid item xs={6} sm={4} md={3} className="customInputFields" style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                                  {this.renderPackageSelectDropdown()}
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} className="customInputFields" style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                                  {this.renderInfoMessageWrapper()}

                                </Grid>
                              </Grid>
                            </Grid>



                          </AccordionDetails>
                        </Accordion>
                        {this.renderAdditionalSubCatAccordion()}
                        {this.state.groupByAdditionalAddon.length > 0 && (
                        <div>
                        {this.state.groupByAdditionalAddon.map((extraAddon, index) => 
                        this.renderAccordionForAddons(extraAddon, index, values, setFieldValue)
                         )}
                        </div>
                        )}

                        <Accordion className="custom-accordion" defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content-additionalNotes"
                            id="panel1a-content-additionalNotes"
                          >
                            <Text style={styles.accordionTittle}>{configJSON.additionalNotes}</Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={3}>
                              <Grid item xs={9} style={{ paddingBottom: 0 }}>
                                <div style={{ marginBottom: "5px", fontSize: "16px", fontFamily: "CircularStdBold" }}>
                                  {configJSON.tellUsProject}
                                </div>
                              </Grid>
                              <Grid item xs={3} style={{ paddingBottom: 0 }}></Grid>
                              <Grid item xs={9} className="tellUsProject customInputFields" style={{ paddingTop: 0, position: 'relative', display: 'flex', height: 'fit-content' }}>
                                <CustomTextInputStyle
                                  minRows={1}
                                  data-testid="note"
                                  placeholder="Type Here"
                                  name="subject"
                                  value={values.note}
                                  maxLength={1000}
                                  style={{
                                    paddingTop: 10,
                                    resize: "vertical",
                                    paddingBottom: 10,
                                    backgroundColor: "#fff",
                                    borderRadius: 0,
                                    outline: 'none'
                                  }}
                                  onChange={
                                    (event: any) => {
                                      setFieldValue("note", event.target.value);
                                      this.setCustomFormProjectDescription(event.target.value)
                                    }}
                                />
                                <Box style={{
                                  right: 30, color: "5A5A5A",
                                  fontSize: 12, fontFamily: 'CircularStd', position: "absolute", bottom: 16, zIndex: 2
                                }}>{this.handleTextLengthCount()}/1000</Box>
                              </Grid>
                              <Grid item xs={3} className="uploadContainer" style={{ paddingTop: 0 }}>
                                <View>

                                  <div className={`buttonWrapper w-40% btn-blue uploadButton`} style={{ width: "40%" }}>
                                    <div className="buttonOutline"></div>
                                    <Button
                                      data-testid="uploadBtn"
                                      onClick={this.onAddBtnClicked}
                                      className="btn-sm"
                                    >Upload</Button>

                                  </div>
                                </View>
                              </Grid>
                              {this.state?.customForm?.attributes && this.state?.customForm?.attributes?.files && (
                                <>
                                  <View
                                    style={{ paddingLeft: 10, width: '80%', maxWidth: '500px' }}>
                                    <Text
                                      style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 20, paddingBottom: 20, lineHeight: 62 }}>Attachments</Text>
                                    <div>
                                      {this.state.customForm.attributes.files.length > 0 ? (
                                        <AttachmentDiv>
                                          {this.state.customForm.attributes.files.map((file: any) => (
                                            <div className="item">
                                              <p style={{
                                                color: "#000000", fontFamily: "CircularStd", textOverflow: "ellipsis",
                                                fontSize: 16, width: "80%", whiteSpace: "nowrap", margin: 0, overflow: "hidden",
                                              }}>{this.getFileName(file.name)}</p>
                                              <CloseIcon onClick={() => this.removeAttachmentsForm([file.id])} style={{ color: "#000", cursor: "pointer" }} />
                                            </div>
                                          ))
                                          }
                                        </AttachmentDiv>
                                      ) : (
                                        <Text style={{
                                          fontFamily: "CircularStd",
                                          color: "#000000", fontSize: 14
                                        }}>
                                          No Files Added</Text>
                                      )}
                                    </div>
                                  </View>
                                </>
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Grid container spacing={3} style={{ marginTop: "5%" }}>
                          <Grid className="actionButtons">
                            <div className={`buttonWrapper w-195 btn-subcategory3`}>
                              <div className={`buttonOutline subcategoryBg3`}></div>
                              <Button
                                data-testid="resetButton"
                                className="btn-sm"
                                onClick={() => this.handleReset(setFieldValue)}
                              >Reset</Button>
                            </div>
                            <div className="buttonWrapper btn-green w-195">
                              <div className="buttonOutline" style={{ backgroundColor: "#00BFBB" }}></div>
                              <Button
                                data-testid="saveForLater"
                                className="btn-sm w-195"
                                onClick={() => {this.setState({ submissionType: "saveForLater" }, this.handleContinueToReview)}}
                              >{configJSON.saveLater}</Button>
                            </div>
                            <div className={`buttonWrapper w-195 btn-blue`}>
                              <div className="buttonOutline"></div>
                              <Button
                                data-testid="continueReview"
                                className="btn-sm w-195"
                                onClick={() => {this.setState({ submissionType: "continueReview" },this.handleContinueToReview)}}
                              > {configJSON.continueReview}</Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
              </Grid>
            </CustomFormContainer>
          }
        </View>

        <Dialog id="model_add" maxWidth="md"
            open={this.state.openDialogName==="Warning"}
            onClose={this.onPopCancel} 
            style={{
            border:'3px solid black'
            }}>
            <DialogContent 
              style={{ 
                alignItems:'center', 
                width:400,
                height:420, 
                border:'3px solid black'}}> 
              <div data-testid="hideModal" 
                onClick={this.onPopCancel} style={{position:"absolute",right:20,top:10,cursor:"pointer",}}>
                  <Image source={imgClose}  resizeMode="cover"
                  style={{
                    height:18,
                    width:18,
                }}
                />
              </div>
                <Box style={{border: '4px solid black', 
                    padding: '20px', backgroundColor: 'var(--Status-Amber-400, #FFBA00)' , height:83,width:83,display: "flex",flexDirection: "column",alignItems: "center",position:'absolute', top:'10%',left:"165px"}}>
                      <Typography variant="h5" component="h2" style={{fontSize: 88,color: "#000",justifyContent: "center", 
                            backgroundColor:"var(--Status-Amber-400, #FFBA00)",display: "flex", alignItems: "center", fontFamily:'CircularStdBold',marginTop:-15}}>?</Typography>
                    <Typography variant="h5" component="h2" style={{ fontSize: 30,color: "#000",justifyContent: "center", 
                          marginBottom: "20px",display: "flex", alignItems: "center", fontFamily:'CircularStdBold',marginTop:35, width:345,backgroundColor:"#fff"}}>
                          Are you sure?</Typography>
                    <GenericLabel color="#5A5A5A" fontFamily='CircularStd' align="center" 
                      fontSize={14} lineHeight={19} pr={15} width="350px">{this.state.warningMessage}</GenericLabel>
                </Box>
                <div style={{ display: 'flex',
                marginTop: 350, marginLeft:'10%'}}>
                                          
                    <View>
                      <div style={{width:150}}
                      className={`buttonWrapper w-220 btn-subcategory3` }>
                          <div className={`buttonOutline subcategoryBg3`}></div>
                      
                      <Button
                        data-test-id="confirmBtn" type="submit" className="btn-sm w-150" onClick={this.onPopCancel}
                      >Cancel</Button>
                      </div>
                    </View>
                  
                    <View>
                      <div className={`buttonWrapper w-150 btn-blue`} style={{marginLeft:20,width:150}}>
                          <div className="buttonOutline"></div>
                          <Button
                            data-test-id="confirmBtn1"
                            type="submit"
                            className="btn-sm w-150" 
                            onClick={this.changeEnquiriesClick}
                          >Proceed</Button>
                      </div>
                    </View>
                </div>
          
            </DialogContent>
        </Dialog>

        <Dialog data-testid="dialog"
          open={this.state.openDialogName === "upload"} className="custom-modal" transitionDuration={0} scroll="body"
          aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description"
        ><UploadDialogContent data-test-id="form">
            <View>
              <div data-testid="hideModal"
                onClick={this.onCancel}
                style={{ position: "absolute", right: 0, top: 0, cursor: "pointer", }}>
                <Image data-testid="closeBtn"
                  source={imgClose} resizeMode="cover"
                  style={{
                    height: 18,
                    width: 18,
                  }}
                />
              </div>
              <Box className="uploadTitleConatiner">
              <Box sx={uploadModalHeaderCss}>
                 {configJSON.uploadHeader}
                </Box>
                <Divider style={dividerCss}/>
              </Box>
              <Box className="mobileTitle">
                <div style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 20, paddingBottom: 20, lineHeight: '44px', fontWeight: 700 }}>
                  Upload Files
                </div>
              </Box>
              <Box className="uploadFileContainer">
                <Grid container spacing={4} >
                  <Grid item xs={6}>
                    <Box
                      onDragOver={this.handleDragOver}
                      onDrop={this.handleDrop}
                      data-test-id="drag"
                      className="uploadFilesBox"
                    >
                      <View style={{ borderColor: "#594FF5", borderStyle: "dotted", borderWidth: 4, paddingBottom: 85, paddingTop: 85, alignItems: "center", justifyContent: "center", paddingLeft: 10, paddingRight: 15 }}>
                        <img src={imgUpload} style={{ marginLeft: 10, marginRight: 10, width: 'min(104px,11vw)' }} alt="imgUpload" />
                        <p className="removeBlockMobile" style={{ fontFamily: "CircularStd", color: "#5A5A5A", fontSize: 18, paddingBottom: 10, textAlign: 'center', marginBottom: 0 }}>{configJSON.uploadText} </p>
                        <p className="removeBlockMobile" style={{ fontFamily: "CircularStd", color: "#5A5A5A", fontSize: 18, paddingBottom: 20, margin: 0 }}>- OR - </p>

                        <div className={`buttonWrapper mt-130 w-275 btn-blue`} style={{ maxWidth: '100%', marginTop: 'min(50px,10vw)' }} >
                          <UploadButtonDiv className="buttonOutline"></UploadButtonDiv>
                          <Button onClick={this.openDocFileInput} data-test-id="openDocFileInput"
                            className="btn-sm w-195"
                          >Browse File
                            <input
                              type="file"
                              multiple
                              accept="/"
                              style={{ display: 'none' }}
                              ref={this.fileInput}
                              onChange={(e: any) => { this.handleDocFileChange(e) }}
                              name="worddoc"
                              data-test-id="fileupload"
                            />
                          </Button>
                        </div>
                      </View>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ overflow: "auto", maxHeight: "450px" }}>
                      <Box className="removeBlockMobile" style={{ marginBottom: 'min(40px,3vw)' }}>
                        <Text style={{ color: "#000", fontSize: 30, fontFamily: "CircularStdBold" }}>{configJSON.uploadFiles}</Text>
                      </Box>
                      {this.state.uploadedFiles.map((file, index) => (
                        <Box className="uploadedFiles fileStatusStyle" key={index} data-test-id="uploadedFile">
                          <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <img src={imgDocument} width={46} style={{ marginRight: 10 }} alt="imgDocument" />
                            <Box sx={{ width: '100%' }}>
                              <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: 'wrap' }}>
                                <Text style={{ color: "#5A5A5A", fontSize: 14, fontFamily: "CircularStd" }}>{this.getFileName(file.name)}</Text>
                                <Text style={{ color: file.status === 'Completed' ? "#00BFBA" : "#5A5A5A", fontSize: 14, fontFamily: "CircularStd" }}>{file.status === 'Completed'
                                  ? 'Completed'
                                  : `${file.progress}%`}</Text>
                              </View>
                              <LinearProgress
                                variant="determinate"
                                value={file.status === 'Completed' ? 100 : file.progress}
                                color={file.status === 'Completed' ? 'secondary' : 'primary'}
                              />
                            </Box>
                          </View>
                        </Box>
                      ))}
                      {this.state.duplicateUploadedFiles.map((file, index) => (
                        <Box className="duplicateFiles fileStatusStyle" key={index}>
                          <Box className="fileContainerClass">
                            <img src={imgDocument} className="imageStyle" alt="imgDocument" />
                            <Box className="cancelProgress">
                              <Box className="fileStatusInnerContainer">
                                <Box className="fileNameStyle" >{this.getFileName(file.name)}</Box>
                                <Box className="fileStatusNameStyle"> {configJSON.cancel}</Box>
                              </Box>
                              <LinearProgress
                                variant="determinate"
                                value={file.progress}
                              />
                            </Box>
                          </Box>
                        </Box>
                      ))}   
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </View>
          </UploadDialogContent>
        </Dialog>



        <Dialog data-testid="dialog" open={this.state.openDialogName === "ClientAdmin"} className="custom-modal" transitionDuration={0} scroll="body">
          <ReviewDialogContent
            style={{
              padding: "0px 0px 30px 0px",
              border: "solid 3px #000",
              overflowX: "hidden",
              minHeight: 760,
            }}
            data-test-id="form">
            <View >
              <Box
                padding={{
                  xs: "15px 30px 25px",
                }}>
                <div
                  data-testid="hideModal"
                  onClick={this.onCancel}
                  style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
                  <Image data-testid="closeBtn" source={imgClose} resizeMode="cover"
                    style={{
                      height: 18,
                      width: 18,
                    }}
                  />
                </div>
                <div
                  style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 30, paddingBottom: 20, lineHeight: 'min(62px,9.5vw)' }}>Review Before Submission</div>
                <View
                  style={{ borderBottomColor: "#CBD5E1", borderBottomWidth: 1, paddingBottom: 10 }}>
                  <Text
                    style={{ color: "#5A5A5A", fontSize: 16, fontFamily: "CircularStd", }}>Check the information you have entered before submitting.</Text>
                </View>
              </Box>
              <View
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 30
                }}>
                <Grid container spacing={4} >

                  <Grid container spacing={3}
                    style=
                    {{
                      paddingLeft: 15,
                      paddingRight: 15, maxHeight: 280,
                      overflow: "auto", marginBottom: "20px"
                    }}
                  >

                    {
                      this.state.customForm
                      && this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.service_name
                      && (
                        <div
                          style={
                            { width: "100%", paddingLeft: "15px" }}
                        >
                          <div style={
                            {
                              fontSize: "16px", color: "#594FF5", fontWeight: 700,
                              paddingBottom: 15
                            }}>
                            {
                              this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.service_name
                            }</div>
                        </div>
                      )
                    }
                    <Grid item xs={
                      12
                    }
                      className="inputFieldsAlignEnd reviewInputFields"
                      style={
                        {
                          paddingLeft: 15, paddingRight: 30,
                          display: 'grid',
                          gridTemplateColumns: 'repeat(4,1fr)',
                          columnGap: '15px',
                          rowGap: '15px',
                          paddingTop: 0,
                          paddingBottom: 0
                        }}>
                      {
                        this.state.customForm
                        &&
                        this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.input_values?.data?.map(
                          (input: any,
                            index: any) =>
                          (
                            input.attributes.user_input
                            &&
                            (
                              <CustomGridItem
                                item xs={3}
                                style={{
                                  display: "flex",
                                  flexDirection: "column", justifyContent: "flex-end"
                                }
                                }
                                key={index
                                }>
                                <div
                                  style=
                                  {{
                                    marginBottom: "5px",
                                    fontSize: "13px",
                                    fontFamily: "CircularStdBold",
                                  }
                                  }>
                                  {
                                    input.attributes.input_field.name
                                  }
                                </div>

                                <Tooltip
                                   title={this.getTooltipProps(input).title}
                                   placement="top"
                                   arrow
                                   disableHoverListener={this.getTooltipProps(input).disableHoverListener}
                                  >

                                  <TextField className="customInput" inputProps={
                                    {
                                      style: {
                                        backgroundColor: "#fff",
                                        color: "#000",
                                        textOverflow: "ellipsis"
                                      }
                                    }
                                  }
                                    value=
                                    {
                                      reviewEventStartTime(input.attributes.input_field.name, input.attributes.user_input)
                                    } fullWidth
                                    variant="outlined" disabled />
                                </Tooltip>
                              </CustomGridItem>
                            )
                          )
                        )
                      }
                    </Grid>

                    {this.state.groupByAdditionalAddon && this.state.groupByAdditionalAddon.map((serviceName, index) => {
                      return serviceName.attributes.service_name == "Event Photography Packages" && (
                        <div key={index} style={{ width: "100%", paddingLeft: "15px" }} data-test-Id="testService">
                          <hr style={{ margin: "20px 0", borderBottom: "1px solid black", width: "100%", paddingLeft: "15px" }} />
                          <div style={{ fontSize: "16px", color: "#594FF5", fontWeight: 700, paddingBottom: 15 }}>{serviceName.attributes.service_name}</div>
                        </div>
                      )
                    })}
                    <Grid item xs={12} className="inputFieldsAlignEnd reviewInputFields" style={{
                      paddingLeft: 15, paddingRight: 30, maxHeight: 280, overflow: "auto", display: 'grid',
                      gridTemplateColumns: 'repeat(4,1fr)', columnGap: '15px', rowGap: '15px', paddingTop: 0, paddingBottom: 0
                    }}>


                      {
                        this.state.groupByAdditionalAddon
                        && this.state.groupByAdditionalAddon.map((service: any) => (
                          service.attributes.service_name === "Event Photography Packages" &&

                          (
                            service.attributes.input_values.data.map((input: any, index: any) => (input.attributes.user_input && (
                              <CustomGridItem item xs={3} key={index} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}><div style={
                                { marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold" }}>
                                {input.attributes.input_field.name}
                              </div>
                                <TextField
                                  className="customInput"
                                  inputProps={{
                                    style: { backgroundColor: "#fff", color: "#000" }
                                  }}
                                  value={input.attributes.user_input}
                                  variant="outlined"
                                  fullWidth
                                  disabled />
                              </CustomGridItem>
                            )
                            )
                            ))

                        ))}</Grid>


                    {this.state.groupByAdditionalAddon && this.state.groupByAdditionalAddon.map((serviceName: { attributes: { service_name: string } }, index: number) => {
                      return serviceName.attributes.service_name == "Event Filming Packages" && (
                        <div key={index} style={{ paddingLeft: "15px", width: "100%", }}
                          data-test-Id="testService">
                          <hr style={{ borderBottom: "1px solid black", margin: "20px 0", paddingLeft: "15px", width: "100%" }} />
                          <div style={{ color: "#594FF5", fontSize: "16px", paddingBottom: 15, fontWeight: 700, }}>
                            {serviceName.attributes.service_name}
                          </div>
                        </div>
                      )
                    })}
                    <Grid item className="reviewInputFields inputFieldsAlignEnd" xs={12} style={{
                      maxHeight: 280, display: 'grid', paddingLeft: 15, paddingRight: 30, overflow: "auto",
                      gridTemplateColumns: 'repeat(4,1fr)', rowGap: '15px', paddingTop: 0, columnGap: '15px', paddingBottom: 0
                    }}>
                      {this.state.groupByAdditionalAddon
                        && this.state.groupByAdditionalAddon.map((service: { attributes: { service_name: string, input_values: { data: { attributes: { user_input: string, input_field: { name: string } } }[] } } }) => (
                          service.attributes.service_name === "Event Filming Packages" && (
                            service.attributes.input_values.data.map((input: { attributes: { user_input: string, input_field: { name: string } } }, index: number) => (input.attributes.user_input && (
                              <CustomGridItem item key={index} xs={3} style={{ flexDirection: "column", justifyContent: "flex-end", display: "flex", }}><div style={
                                { fontSize: "13px", marginBottom: "5px", fontFamily: "CircularStdBold" }}>
                                {input.attributes.input_field.name}
                              </div>
                                <TextField
                                  className="customInput" inputProps={{ style: { backgroundColor: "#fff", color: "#000" } }}
                                  value={input.attributes.user_input}
                                  variant="outlined"
                                  fullWidth disabled />
                              </CustomGridItem>
                            )
                            )
                            ))
                        ))}</Grid>


                    {this.state.groupByAdditionalAddon
                      &&
                      this.state.groupByAdditionalAddon.map((serviceName, index) => {
                        return serviceName.attributes.service_name === "AV Packages"
                          && (<div key={
                            index}
                            style={{ width: "100%", paddingLeft: "15px" }}
                          >
                            <hr style={{
                              margin: "20px 0",
                              borderBottom: "1px solid black",
                              width: "100%"
                            }
                            }
                            />
                            <div style={{
                              fontSize: "16px",
                              color: "#594FF5",
                              fontWeight: 700,
                              paddingBottom: 15
                            }
                            }>{serviceName.attributes.service_name}
                            </div>
                          </div>
                          )
                      })}
                    <Grid item xs={12} className="inputFieldsAlignEnd reviewInputFields" style={{
                      paddingLeft: 15, paddingRight: 30, maxHeight: 280, overflow: "auto", display: 'grid',
                      gridTemplateColumns: 'repeat(4,1fr)', columnGap: '15px', paddingTop: 0, paddingBottom: 0,
                      rowGap: '15px'
                    }}>
                      {this.state.groupByAdditionalAddon && this.state.groupByAdditionalAddon.map(
                        (service: any) => (
                          service.attributes.service_name === "AV Packages" && (
                            service.attributes.input_values.data.map((input: any, index: any) => (input.attributes.user_input
                              && (<CustomGridItem item xs={3} key={index} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                <div style={
                                  {
                                    marginBottom: "5px",
                                    fontSize: "13px", fontFamily: "CircularStdBold"
                                  }}>
                                  {input.attributes.input_field.name}
                                </div>
                                <TextField className="customInput"
                                  inputProps={
                                    {
                                      style:
                                      {
                                        backgroundColor: "#fff",
                                        color: "#000"
                                      }
                                    }}
                                  value={input.attributes.user_input}
                                  variant=
                                  "outlined"
                                  fullWidth
                                  disabled /></CustomGridItem>
                              ))))

                        )
                      )
                      }</Grid>



                    {
                      this.state.groupByAdditionalAddon && this.state.groupByAdditionalAddon.map((serviceName,
                        index) => {
                        return serviceName.attributes.service_name === "Live Streaming Packages" &&
                          (
                            <div key={index} style={
                              { width: "100%", paddingLeft: "15px" }}
                            >
                              <hr style={{ margin: "20px 0", borderBottom: "1px solid black", width: "100%" }}
                              />
                              <div style={
                                {
                                  fontSize: "16px",
                                  color: "#594FF5", fontWeight: 700, paddingBottom: 15
                                }}
                              >{serviceName.attributes.service_name}</div>
                            </div>
                          )
                      }
                      )
                    }
                    <Grid item xs={12} className="inputFieldsAlignEnd reviewInputFields"
                      style={{
                        paddingLeft: 15,
                        paddingRight: 30, maxHeight: 280, overflow: "auto", display: 'grid',
                        gridTemplateColumns: 'repeat(4,1fr)', columnGap: '15px', rowGap: '15px', paddingTop: 0, paddingBottom: 0
                      }}>
                      {
                        this.state.groupByAdditionalAddon
                        &&
                        this.state.groupByAdditionalAddon.map(
                          (service: any) => (
                            service.attributes.service_name === "Live Streaming Packages" && (
                              service.attributes.input_values.data.map(
                                (input: any,
                                  index: any) => (input.attributes.user_input
                                    && (
                                      <CustomGridItem
                                        item xs={3}
                                        key={index} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                        <div style={{ marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold" }}>
                                          {input.attributes.input_field.name}
                                        </div><TextField
                                          className="customInput"
                                          inputProps={
                                            {
                                              style: {
                                                backgroundColor: "#fff",
                                                color: "#000"
                                              }
                                            }}
                                          value={input.attributes.user_input}
                                          variant="outlined" fullWidth
                                          disabled />
                                      </CustomGridItem>
                                    ))))))
                      }
                    </Grid>





                    {this.state.groupByAdditionalAddon &&
                      this.state.groupByAdditionalAddon.map(
                        (serviceName, index) => {
                          return serviceName.attributes.service_name === "Virtual Packages" &&
                            (
                              <div
                                key={index}
                                style={{
                                  width: "100%",
                                  paddingLeft: "15px"
                                }}>
                                <hr
                                  style={{
                                    margin: "20px 0", borderBottom: "1px solid black", width: "100%"
                                  }} />
                                <div style={
                                  { fontSize: "16px", color: "#594FF5", fontWeight: 700, paddingBottom: 15 }}>
                                  {serviceName.attributes.service_name}</div>
                              </div>
                            )
                        })}
                    <Grid item
                      xs={12}
                      className="inputFieldsAlignEnd reviewInputFields"
                      style={{
                        paddingLeft: 15, paddingRight: 30, maxHeight: 280, overflow: "auto", display: 'grid',
                        gridTemplateColumns: 'repeat(4,1fr)', columnGap: '15px', rowGap: '15px', paddingTop: 0, paddingBottom: 0
                      }}>
                      {
                        this.state.groupByAdditionalAddon
                        && this.state.groupByAdditionalAddon.map(
                          (service: any) => (
                            service.attributes.service_name === "Virtual Packages" &&
                            (
                              service.attributes.input_values.data.map((input: any, index: any) => (
                                input.attributes.user_input
                                &&
                                (
                                  <CustomGridItem item xs={3} key={index}
                                    style={
                                      { display: "flex", flexDirection: "column", justifyContent: "flex-end" }
                                    }>
                                    <div style={
                                      { marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold" }}>
                                      {
                                        input.attributes.input_field.name}
                                    </div>
                                    <TextField className="customInput"
                                      inputProps={
                                        {
                                          style: {
                                            backgroundColor: "#fff",
                                            color: "#000"
                                          }
                                        }}
                                      value={input.attributes.user_input}
                                      variant="outlined" fullWidth
                                      disabled /></CustomGridItem>)
                              )))
                          ))}
                    </Grid>

                    {
                      this.state.customForm
                      && this.state.customForm.attributes?.note
                      && (
                        <div style={
                          { width: "100%", paddingLeft: "15px", }}
                        >
                          <hr style={{ borderBottom: "1px solid black", margin: "20px 0", width: "100%" }}
                          />
                          <div style={
                            {
                              color: "#594FF5", fontSize: "16px",
                              fontWeight: 700, paddingBottom: 15
                            }}
                          >{configJSON.projectNotes}</div>
                        </div>
                      )
                    }
                    <Grid item
                      xs={12}
                      className="inputFieldsAlignEnd reviewInputFields"
                      style={{
                        paddingLeft: 15, paddingRight: 30, maxHeight: 280, overflow: "auto"
                        , columnGap: '15px', rowGap: '15px', paddingTop: 0, paddingBottom: 0
                      }}>
                      {
                        this.state.customForm.attributes?.note &&
                        (
                          <CustomGridItem item xs={6}
                            style={
                              { display: "flex", flexDirection: "column", justifyContent: "flex-end", boxSizing: "border-box", paddingRight: "7.5px" }
                            }>
                            <div style={
                              { marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold" }}>
                              {
                                configJSON.tellUsProject}
                            </div>
                            <Tooltip
                              title={this.state.customForm.attributes?.note}
                              placement="top">
                              <TextField className="customInput overflowFieldsEllipsis"
                                inputProps={
                                  {
                                    style: {
                                      backgroundColor: "#fff",
                                      color: "#000"
                                    }
                                  }}
                                value={this.state.customForm.attributes?.note}
                                variant="outlined" fullWidth
                                disabled />
                            </Tooltip>
                          </CustomGridItem>
                        )
                      }
                    </Grid>

                  </Grid>
                  <Grid className="priceDetailsContainer" container>
                    <Grid
                      item xs={6} >
                      {this.renderCustomFormPopupPriceDetails()}
                    </Grid>


                    <Grid
                      item xs={6} >
                      {this.state.customForm?.attributes && this.state.customForm?.attributes?.files && (
                        <>
                          <View
                            style={{ width: "100%", maxWidth: '100%' }}>
                            <Text style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 30, lineHeight: 62 }}>Attachments</Text>
                            <div>
                              {this.renderDownloadFileButton()}
                            </div>
                          </View>
                        </>
                      )}
                    </Grid>
                  </Grid>



                  <View
                    style={{
                      paddingTop: 'min(30px,4vw)',
                      width: '100%',
                      maxWidth: '100%',
                      paddingBottom: 20
                    }}>
                    <Grid
                      container>
                      <Grid className="reviewActionButtons" item>
                  
                  <div className="backButtonText" data-testid="hideModalText" onClick={this.onCancel} style={{ alignItems: 'center', justifyContent: 'center', cursor: "pointer", display: 'flex', textAlign: "center"}}>
                          <div style={{ textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: 16 }}>Back</div>
                        </div>

                      <div className="buttonWrapper btn-yellow w-195">
                              <div className="buttonOutline" style={{ backgroundColor: "#ffba00" }}></div>
                              <Button
                                type="submit"
                                data-testid="saveForLater"
                                className="btn-sm w-195"
                                onClick={() => {
                                  this.setState({ submissionType: "saveForLater" }, this.goToDraftEnquire);
                                }}
                              >{configJSON.saveLater}</Button>
                            </div>
                        <div
                          className={`buttonWrapper w-195 btn-subcategory3`}>
                          <div className="buttonOutline" style={{ backgroundColor: "#FF5B74" }}></div>
                          {this.renderDownloadButton(this.state.errorCell, `${baseURL + "/download_pdf/" + this.state.enquiryDetails.id}`)}
                        </div>
                        <Tooltip title={!this.state.is_service ? "You cannot make changes to this enquiry as this package/service has been deactivated for your organisation." : ""} placement="top">
                          <div className="buttonWrapper btn-green w-195">
                            <div className="buttonOutline" style={{ backgroundColor: !this.state.is_service ? '#ccc' : "#00BFBB" }}></div>
                            <Button
                              data-testid="resetButton"
                              className="btn-sm"
                              onClick={this.meetLink}
                              style={{ backgroundColor: !this.state.is_service ? '#ccc' : '' }}
                              disabled={!this.state.is_service}
                            >Schedule Meeting</Button>
                          </div>
                        </Tooltip>
                        <Tooltip title={!this.state.is_service ? "You cannot make changes to this enquiry as this package/service has been deactivated for your organisation." : ""} placement="top">
                          <div className={`buttonWrapper w-195 btn-blue`}>
                            <div className="buttonOutline" style={{ backgroundColor: !this.state.is_service ? '#ccc' : "" }}></div>
                            <Button
                              type="submit"
                              className="btn-sm w-195"
                              onClick={this.goToiCalendar}
                              style={{ backgroundColor: !this.state.is_service ? '#ccc' : '' }}
                            >iCalendar</Button>
                          </div>
                        </Tooltip>
                        <Tooltip title={!this.state.is_service ? "You cannot make changes to this enquiry as this package/service has been deactivated for your organisation." : ""} placement="top">
                          <div className="buttonWrapper btn-green w-195">
                            <div className="buttonOutline" style={{ backgroundColor: !this.state.is_service ? '#ccc' : "#00BFBB" }}></div>
                       {this.renderApproveorSubmitButtonAdmin()}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </View>

                </Grid>
              </View>
            </View>
          </ReviewDialogContent>
        </Dialog>
        <Dialog
          data-testid="dialog"
          open={this.state.openDialogName === "ClientUser"}
          className="custom-modal"
          transitionDuration={0}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          scroll="body"

        >
          <ReviewDialogContent style={{ padding: "0px 0px 30px 0px", border: "solid 3px #000", overflowX: "hidden", minHeight: 760 }} data-test-id="form">
            <View >
              <Box padding={{ xs: "15px 30px 25px", }}>
                <div data-testid="hideModal" onClick={this.onCancel} style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
                  <Image
                    data-testid="closeBtn"
                    source={imgClose}
                    resizeMode="cover"
                    style={{ height: 18, width: 18, }}
                  />
                </div>
                <div style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 30, paddingBottom: 20, lineHeight: 'min(62px,9.5vw)' }}>Review Before Submission</div>
                <View style={{ borderBottomColor: "#CBD5E1", borderBottomWidth: 1, paddingBottom: 10 }}>
                  <Text style={{ color: "#5A5A5A", fontSize: 16, fontFamily: "CircularStd", }}>Check the information you have entered before submitting.</Text>
                </View>
              </Box>
              <View style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 30 }}>
                <Grid container spacing={4} >

                  <Grid
                    container
                    spacing={3} style={{ paddingLeft: 15, paddingRight: 30, maxHeight: 280, overflow: "auto", marginBottom: "20px" }}
                  >

                    {this.state.customForm
                      && this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.service_name
                      && (
                        <div
                          style={{ width: "100%", paddingLeft: "15px" }}
                        >
                          <div
                            style={{ fontSize: "16px", color: "#594FF5", fontWeight: 700, paddingBottom: 15 }}
                          >
                            {this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.service_name}
                          </div>
                        </div>
                      )
                    }
                    <Grid
                      item xs={12}
                      className="inputFieldsAlignEnd reviewInputFields"
                      style={{
                        paddingLeft: 15, paddingRight: 30,
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4,1fr)',
                        columnGap: '15px',
                        rowGap: '15px', paddingTop: 0,
                        paddingBottom: 0
                      }
                      }>
                      {
                        this.state.customForm
                        &&
                        this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.input_values?.data?.map(
                          (input: any, index: any) =>
                          (
                            input.attributes.user_input
                            &&
                            (
                              <CustomGridItem
                                item xs={3}
                                style=
                                {{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-end"
                                }}
                                key={index}
                              >
                                <div
                                  style=
                                  {
                                    {
                                      marginBottom: "5px",
                                      fontSize: "13px",
                                      fontFamily: "CircularStdBold",
                                    }}>
                                  {input.attributes.input_field.name}
                                </div>
                                <Tooltip
                                   title={this.getTooltipProps(input).title}
                                   placement="top"
                                   arrow
                                   disableHoverListener={this.getTooltipProps(input).disableHoverListener}
                                  >
                                  <TextField
                                    className="customInput"
                                    inputProps=
                                    {
                                      {
                                        style: {
                                          backgroundColor: "#fff", color: "#000", textOverflow: "ellipsis"
                                        }
                                      }}
                                    value={
                                      reviewEventStartTime(input.attributes.input_field.name, input.attributes.user_input)
                                    }
                                    variant="outlined" fullWidth disabled
                                  />
                                </Tooltip>
                              </CustomGridItem>
                            )
                          )
                        )
                      }
                    </Grid>

                    {this.state.groupByAdditionalAddon
                      && this.state.groupByAdditionalAddon.map(
                        (serviceName, index) => {
                          return serviceName.attributes.service_name == "Event Photography Packages" &&
                            (
                              <div
                                key={index}
                                style={{ width: "100%", paddingLeft: "15px" }} data-test-Id="testService"
                              >
                                <hr
                                  style={{
                                    margin:
                                      "20px 0", borderBottom: "1px solid black",
                                    width: "100%", paddingLeft: "15px"
                                  }} />
                                <div style={{
                                  fontSize: "16px",
                                  color: "#594FF5", fontWeight: 700, paddingBottom: 15
                                }}>
                                  {serviceName.attributes.service_name}
                                </div>
                              </div>
                            )
                        })}
                    <Grid item xs={12}
                      className="inputFieldsAlignEnd reviewInputFields" style={{
                        paddingLeft: 15,
                        paddingRight: 30, maxHeight: 280,
                        overflow: "auto", display: 'grid',
                        gridTemplateColumns: 'repeat(4,1fr)', columnGap: '15px',
                        rowGap: '15px', paddingTop: 0,
                        paddingBottom: 0
                      }}>


                      {
                        this.state.groupByAdditionalAddon
                        && this.state.groupByAdditionalAddon.map(
                          (service: any) => (
                            service.attributes.service_name === "Event Photography Packages" &&

                            (
                              service.attributes.input_values.data.map(
                                (input: any, index: any) => (input.attributes.user_input &&
                                  (
                                    <CustomGridItem
                                      item
                                      xs={3} key={index} style={{
                                        display: "flex", flexDirection: "column",
                                        justifyContent: "flex-end"
                                      }}>
                                      <div style={
                                        {
                                          marginBottom: "5px", fontSize: "13px",
                                          fontFamily: "CircularStdBold"
                                        }}>
                                        {input.attributes.input_field.name}
                                      </div>
                                      <TextField className="customInput"
                                        inputProps={{
                                          style: {
                                            backgroundColor: "#fff",
                                            color: "#000"
                                          }
                                        }}
                                        value={
                                          input.attributes.user_input
                                        }
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                      />
                                    </CustomGridItem>))))
                          ))}</Grid>


                    {
                      this.state.groupByAdditionalAddon &&
                      this.state.groupByAdditionalAddon.map((serviceName: { attributes: { service_name: string } }, index: number) => {
                        return serviceName.attributes.service_name == "Event Filming Packages" && (
                          <div key={index} style=
                            {{ paddingLeft: "15px", width: "100%", }}
                            data-test-Id="testService">
                            <hr style={{
                              margin: "20px 0", width: "100%",
                              paddingLeft: "15px", borderBottom: "1px solid black",
                            }} />
                            <div style={{
                              color: "#594FF5", paddingBottom: 15, fontSize: "16px",
                              fontWeight: 700,
                            }}>
                              {serviceName.attributes.service_name}
                            </div>
                          </div>
                        )
                      })}
                    <Grid item className="reviewInputFields inputFieldsAlignEnd" xs={12} style={{
                      maxHeight: 280, display: 'grid', rowGap: '15px', paddingLeft: 15, overflow: "auto", paddingBottom: 0,
                      gridTemplateColumns: 'repeat(4,1fr)', paddingTop: 0, paddingRight: 30, columnGap: '15px',
                    }}>
                      {this.state.groupByAdditionalAddon
                        && this.state.groupByAdditionalAddon.map((service: { attributes: { service_name: string, input_values: { data: { attributes: { user_input: string, input_field: { name: string } } }[] } } }) => (
                          service.attributes.service_name === "Event Filming Packages" &&
                          (
                            service.attributes.input_values.data.map((input: { attributes: { user_input: string, input_field: { name: string } } }, index: number) => (input.attributes.user_input && (
                              <CustomGridItem item key={index} xs={3} style={{
                                justifyContent: "flex-end", flexDirection: "column", display: "flex",
                              }}>
                                <div style={{ marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold" }}> {input.attributes.input_field.name} </div>
                                <TextField
                                  className="customInput"
                                  fullWidth
                                  inputProps=
                                  {{
                                    style:
                                      { backgroundColor: "#fff", color: "#000" }
                                  }}
                                  value={input.attributes.user_input} variant="outlined" disabled />
                              </CustomGridItem>
                            ))))
                        ))}
                    </Grid>


                    {
                      this.state.groupByAdditionalAddon &&
                      this.state.groupByAdditionalAddon.map(
                        (serviceName, index) => {
                          return serviceName.attributes.service_name === "AV Packages" && (
                            <div key={
                              index}
                              style={{ width: "100%", paddingLeft: "15px" }}>
                              <hr style={
                                {
                                  margin: "20px 0", borderBottom: "1px solid black",
                                  width: "100%"
                                }
                              } />
                              <div style={
                                {
                                  fontSize: "16px", color: "#594FF5", fontWeight: 700,
                                  paddingBottom: 15
                                }
                              }>{serviceName.attributes.service_name}</div>
                            </div>
                          )
                        }
                      )}
                    <Grid
                      item xs={12}
                      className="inputFieldsAlignEnd reviewInputFields"
                      style={
                        {
                          paddingLeft: 15,
                          paddingRight: 30,
                          maxHeight: 280,
                          overflow: "auto",
                          display: 'grid',
                          gridTemplateColumns: 'repeat(4,1fr)',
                          columnGap: '15px',
                          rowGap: '15px', paddingTop: 0,
                          paddingBottom: 0
                        }
                      }
                    >
                      {this.state.groupByAdditionalAddon
                        &&
                        this.state.groupByAdditionalAddon.map(
                          (service: any) => (
                            service.attributes.service_name === "AV Packages" && (
                              service.attributes.input_values.data.map(
                                (input: any, index: any) => (input.attributes.user_input
                                  && (
                                    <CustomGridItem item
                                      xs={3}
                                      key={index}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end"
                                      }
                                      }
                                    ><div style={
                                      {
                                        marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold"
                                      }
                                    }
                                    >
                                        {
                                          input.attributes.input_field.name}</div>
                                      <TextField
                                        className="customInput"
                                        inputProps={
                                          {
                                            style:
                                            {
                                              backgroundColor: "#fff",
                                              color: "#000"
                                            }
                                          }
                                        }
                                        value={input.attributes.user_input}
                                        variant=
                                        "outlined"
                                        fullWidth
                                        disabled /></CustomGridItem>
                                  ))))))
                      }</Grid>



                    {
                      this.state.groupByAdditionalAddon &&
                      this.state.groupByAdditionalAddon.map(
                        (serviceName,
                          index) => {
                          return serviceName.attributes.service_name === "Live Streaming Packages" &&
                            (<div key={index} style={
                              { width: "100%", paddingLeft: "15px" }}>
                              <hr style={{
                                margin: "20px 0",
                                borderBottom: "1px solid black",
                                width: "100%"
                              }
                              }
                              />
                              <div style={
                                {
                                  fontSize: "16px",
                                  color: "#594FF5",
                                  fontWeight: 700,
                                  paddingBottom: 15
                                }}>{serviceName.attributes.service_name}</div>
                            </div>
                            )
                        }
                      )
                    }
                    <Grid item
                      xs={12}
                      className="inputFieldsAlignEnd reviewInputFields"
                      style={{
                        paddingLeft: 15,
                        paddingRight: 30,
                        maxHeight: 280,
                        overflow: "auto",
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4,1fr)', columnGap: '15px', rowGap: '15px', paddingTop: 0, paddingBottom: 0
                      }}>
                      {
                        this.state.groupByAdditionalAddon
                        &&
                        this.state.groupByAdditionalAddon.map(
                          (service: any) => (
                            service.attributes.service_name === "Live Streaming Packages" && (
                              service.attributes.input_values.data.map(
                                (input: any,
                                  index: any) => (input.attributes.user_input
                                    && (
                                      <CustomGridItem
                                        item xs={3}
                                        key={index}
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "flex-end"
                                        }}
                                      >
                                        <div style={{
                                          marginBottom: "5px",
                                          fontSize: "13px",
                                          fontFamily: "CircularStdBold"
                                        }}>
                                          {input.attributes.input_field.name}
                                        </div>
                                        <TextField
                                          className="customInput"
                                          inputProps={
                                            {
                                              style: {
                                                backgroundColor: "#fff",
                                                color: "#000"
                                              }
                                            }}
                                          value={input.attributes.user_input}
                                          variant="outlined" fullWidth
                                          disabled />
                                      </CustomGridItem>
                                    )
                                )
                              )
                            )
                          )
                        )
                      }</Grid>




                    {this.state.groupByAdditionalAddon &&
                      this.state.groupByAdditionalAddon.map(
                        (serviceName, index) => {
                          return serviceName.attributes.service_name === "Virtual Packages"
                            &&
                            (
                              <div
                                key={index}
                                style=
                                {
                                  {
                                    width: "100%",
                                    paddingLeft: "15px"
                                  }
                                }>
                                <hr
                                  style={{
                                    margin: "20px 0",
                                    borderBottom: "1px solid black",
                                    width: "100%"
                                  }}
                                />
                                <div style={
                                  {
                                    fontSize: "16px",
                                    color: "#594FF5",
                                    fontWeight: 700,
                                    paddingBottom: 15
                                  }
                                }>
                                  {serviceName.attributes.service_name}</div>
                              </div>
                            )
                        })
                    }
                    <Grid item
                      xs={12}
                      className="inputFieldsAlignEnd reviewInputFields"
                      style={{
                        paddingLeft: 15,
                        paddingRight: 30,
                        maxHeight: 280,
                        overflow: "auto",
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4,1fr)', columnGap: '15px', rowGap: '15px', paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      {
                        this.state.groupByAdditionalAddon
                        && this.state.groupByAdditionalAddon.map(
                          (service: any) => (
                            service.attributes.service_name === "Virtual Packages" &&
                            (
                              service.attributes.input_values.data.map(
                                (input: any, index: any) => (
                                  input.attributes.user_input
                                  &&
                                  (
                                    <CustomGridItem item
                                      xs={3}
                                      key={index}
                                      style={
                                        {
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "flex-end"
                                        }
                                      }>
                                      <div style={
                                        {
                                          marginBottom: "5px",
                                          fontSize: "13px",
                                          fontFamily: "CircularStdBold"
                                        }}
                                      >
                                        {
                                          input.attributes.input_field.name}
                                      </div>
                                      <TextField className="customInput"
                                        inputProps={
                                          {
                                            style: {
                                              backgroundColor: "#fff",
                                              color: "#000"
                                            }
                                          }
                                        }
                                        value={input.attributes.user_input}
                                        variant="outlined"
                                        fullWidth
                                        disabled /></CustomGridItem>
                                  )
                                ))
                            )
                          ))
                      }
                    </Grid>

                    {
                      this.state.customForm
                      && this.state.customForm.attributes?.note
                      && (
                        <div style={{ paddingLeft: "15px", width: "100%", }}>
                          <hr style={{ margin: "20px 0", width: "100%", borderBottom: "1px solid black", }} />
                          <div style={{ fontSize: "16px", paddingBottom: 15, fontWeight: 700, color: "#594FF5", }}>{configJSON.projectNotes}</div>
                        </div>
                      )
                    }
                    <Grid item xs={12} className="reviewInputFields inputFieldsAlignEnd"
                      style={{
                        paddingLeft: 15, rowGap: '15px', overflow: "auto", paddingTop: 0
                        , columnGap: '15px', maxHeight: 280, paddingBottom: 0, paddingRight: 30,
                      }}>
                      {
                        this.state.customForm.attributes?.note &&
                        (
                          <CustomGridItem item xs={6} style={{
                            display: "flex", justifyContent: "flex-end", boxSizing: "border-box", paddingRight: "7.5px", flexDirection: "column",
                          }}>
                            <div style={
                              { fontFamily: "CircularStdBold", marginBottom: "5px", fontSize: "13px" }}>
                              {configJSON.tellUsProject}
                            </div>
                            <Tooltip placement="top"
                              title={this.state.customForm.attributes?.note}>
                              <TextField className="customInput overflowFieldsEllipsis"
                                inputProps={{
                                  style: {
                                    backgroundColor: "#fff", color: "#000"
                                  }
                                }}
                                value={this.state.customForm.attributes.note}
                                variant="outlined" fullWidth disabled />
                            </Tooltip>
                          </CustomGridItem>
                        )
                      }
                    </Grid>

                  </Grid>
                  <Grid className="priceDetailsContainer" container>
                    <Grid item xs={6}>
                      {this.renderCustomFormPopupPriceDetails()}
                    </Grid>
                    <Grid item xs={6} >
                      {this.renderAttachmentsFunction()}
                    </Grid>

                  </Grid>

                  <View style={{ paddingTop: 'min(30px,4vw)', paddingRight: 30, width: '100%', maxWidth: '100%', paddingLeft: 30, paddingBottom: 20 }}>
                    <Grid container>
                      <Grid item className="reviewActionButtons">
                        <div className="backButton" data-testid="backButton" onClick={this.onCancel} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer", textAlign: "center", width: '70px', height: '42px' }}>
                          <Text style={{ textDecorationStyle: "solid", textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: 16, marginTop: 'auto', marginBottom: 'auto' }}>Back</Text>
                        </div>
                        <div className="buttonWrapper btn-yellow w-195">
                              <div className="buttonOutline" style={{ backgroundColor: "#ffba00" }}></div>
                              <Button
                                type="submit"
                                data-testid="saveForLater"
                                className="btn-sm w-195"
                                onClick={() => {
                                  this.setState({ submissionType: "saveForLater" }, this.goToDraftEnquire);
                                }}
                              >{configJSON.saveLater}</Button>
                            </div>
                        <div className={`buttonWrapper w-195 btn-subcategory3`}>
                          <div className="buttonOutline" style={{ backgroundColor: "#FF5B74" }}></div>
                          {this.renderDownloadButton(this.state.errorCell, `${baseURL + "/download_pdf/" + this.state.enquiryDetails.id}`)}
                        </div>
                        <Tooltip title={!this.state.is_service ? "You cannot make changes to this enquiry as this package/service has been deactivated for your organisation." : ""} placement="top">
                          <div className={`buttonWrapper w-195 btn-blue`}>
                            <div className="buttonOutline" style={{ backgroundColor: !this.state.is_service ? '#ccc' : "" }}></div>
                            {this.renderApproveorSubmitButtonUser()}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </View>

                </Grid>
              </View>
            </View>
          </ReviewDialogContent>
        </Dialog>
        <Dialog id="model_add" maxWidth="md"
          open={this.state.openDialogName === "submit successfully ClientAdmin"}
          onClick={this.goToEnquiryCustom}
          style={{ border: '3px solid black' }} scroll="body">
          <ResponsiveDialogContent className="pt-65 pb-80" style={{ alignItems: 'center', width: '480px', minHeight: '448px', maxWidth: '100%', display: 'flex', border: '3px solid black', boxSizing: "border-box", flexDirection: 'column' }}>
            <div className="closeWrapper" data-testid="hideModal" onClick={this.goToEnquiryCustom} style={{ right: '20px', position: "absolute", cursor: "pointer", top: '20px', }}>
              <Image source={imgClose} resizeMode="cover" style={{ height: '20px', width: '20px', }} />
            </div>
            <Box className="contentContainer" style={{ display: 'flex', justifyContent: 'center', gap: '25px', flexDirection: 'column', alignItems: 'center' }}>
              <Box className="contentImage" style={{ border: 'solid 3px #000', width: 'fit-content', backgroundColor: '#00BFBA', padding: '10px', }}>
                <CheckIcon style={{ fontSize: '48px', width: '60px', height: '60px', color: 'black' }} />
              </Box>
              {this.isBespokeClientAdmin()}
            </Box>
          </ResponsiveDialogContent>
        </Dialog>

        <Dialog id="model_add" maxWidth="md"
          open={this.state.openDialogName === "submit successfully ClientUser"}
          style={{ border: '3px solid black' }}>
          <ResponsiveDialogContent className="pt-65 pb-80" style={{ alignItems: 'center', maxWidth: '100%', width: '480px', minHeight: '448px', border: '3px solid black', display: 'flex', flexDirection: 'column', boxSizing: "border-box" }}>
            <div className="closeWrapper" data-testid="hideModal" onClick={this.goToEnquiryCustom} style={{ position: "absolute", right: '20px', top: '20px', cursor: "pointer" }}>
              <Image source={imgClose} resizeMode="cover" style={{ height: '20px', width: '20px', }} />
            </div>
            <Box className="contentContainer" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '25px' }}>
              <Box className="contentImage" style={{ border: 'solid 3px #000', padding: '10px', width: 'fit-content', backgroundColor: '#00BFBA' }}>
                <CheckIcon style={{ fontSize: '48px', color: 'black', height: '60px', width: '60px' }} />
              </Box>
              {this.isBespokeClientUser()}
            </Box>
          </ResponsiveDialogContent>
        </Dialog>
        <Dialog id="model_add" maxWidth="md"
          open={this.state.openDialogName === "Error"} onClose={this.onCancel}
          style={{ border: '3px solid black' }}>
          <ResponsiveDialogContent className="pt-100" style={{
            alignItems: 'center', boxSizing: 'border-box',
            maxWidth: '100%', width: '480px',
            display: 'flex', flexDirection: 'column', minHeight: '465px', border: '3px solid black',
          }}>
            <div className="closeWrapper" data-testid="hideModal" onClick={this.onCancel} style={{
              position: "absolute", cursor: "pointer",
              top: '20px', right: '20px'
            }}>
              <Image source={imgClose} resizeMode="cover" style={{
                height: '20px', width: '20px',
              }} />
            </div>
            <Box className="contentContainer" style={{
              display: 'flex', justifyContent: 'center',
              alignItems: 'center', flexDirection: 'column', gap: '25px'
            }}>
              <Box className="contentImage" style={{
                backgroundColor: '#FF5B74', border: 'solid 3px #000',
                width: 'fit-content', padding: '10px',
              }}>
                <ClearIcon style={{
                  fontSize: '48px', width: '60px',
                  height: '60px', color: 'black',
                }} />
              </Box>
              <Typography className="heading" variant="h5" component="h2" style={{ fontSize: 30, marginBottom: "0", backgroundColor: 'transparent', fontFamily: 'CircularStdBold', color: "#000", textAlign: 'center', }}>Oops!</Typography>
              <div className="text" style={{
                color: "#5A5A5A", textAlign: 'center', fontSize: 14,
                lineHeight: '19px', maxWidth: 380, fontFamily: 'CircularStd',
                marginTop: 10,
              }}>Inquiry already submitted.</div>
            </Box>
          </ResponsiveDialogContent>
        </Dialog>

        <Dialog id="model_add" maxWidth="md"
          open={this.state.openDialogName === "Error 2"} onClose={this.onCancel}
          style={{ border: '3px solid black' }}>
          <ResponsiveDialogContent className="pt-100" style={{
            maxWidth: '100%', width: '480px', flexDirection: 'column',
            display: 'flex', alignItems: 'center', border: '3px solid black', boxSizing: 'border-box', minHeight: '465px',
          }}>
            <div className="closeWrapper" data-testid="hideModal" onClick={this.onCancel} style={{
              position: "absolute", cursor: "pointer",
              top: '20px', right: '20px'
            }}>
              <Image source={imgClose} resizeMode="cover" style={{
                height: '20px',
                width: '20px',
              }} />
            </div>
            <Box className="contentContainer" style={{
              gap: '25px', justifyContent: 'center',
              alignItems: 'center', display: 'flex', flexDirection: 'column',
            }}>
              <Box className="contentImage" style={{
                backgroundColor: '#FF5B74', padding: '10px',
                width: 'fit-content', border: 'solid 3px #000',
              }}>
                <ClearIcon style={{
                  height: '60px', fontSize: '48px', width: '60px',
                  color: 'black'
                }} />
              </Box>
              <Typography className="heading" variant="h5" component="h2" style={{
                color: "#000", fontSize: 30, fontFamily: 'CircularStdBold',
                backgroundColor: 'transparent', textAlign: 'center', marginBottom: "0",
              }}>Oops!</Typography>
              <div className="text" style={{
                color: "#5A5A5A", textAlign: 'center', marginTop: 10, fontFamily: 'CircularStd',
                lineHeight: '19px', maxWidth: 380, fontSize: 14,
              }}>{this.state.errorMessage}</div>
            </Box>
          </ResponsiveDialogContent>
        </Dialog>

        <Dialog  maxWidth="md" id="model_add"
          onClose={this.onCancel}
          open={this.state.openDialogName === "ErrorForPackages"} 
          style={{ border: '3px solid black' }}>
          <ResponsiveDialogContent 
          className="pt-100" style={{
            maxWidth: '100%', display: 'flex', 
            alignItems: 'center', border: '3px solid black',
            width: '480px', flexDirection: 'column', 
            boxSizing: 'border-box', minHeight: '465px',
          }}>
            <div data-testid="hideModal" className="closeWrapper" 
             onClick={this.onCancel} style={{
              top: '20px', right: '20px',
              position: "absolute", 
              cursor: "pointer",
            }}>
              <Image source={imgClose} resizeMode="cover" style={{
                width: '20px',
                height: '20px',
              }} />
            </div>
            <Box className="contentContainer" style={{
              justifyContent: 'center',display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center',gap: '25px',  
            }}>
              <Box className="contentImage" style={{
                backgroundColor: '#FF5B74', 
                border: 'solid 3px #000',
                width: 'fit-content', padding: '10px',
              }}>
                <ClearIcon style={{
                  height: '60px', 
                  fontSize: '48px', 
                  color: 'black',
                  width: '60px',
                }} />
              </Box>
              <Typography variant="h5" 
                component="h2" 
                className="heading"  style={{
                color: "#000", fontSize: 30, fontFamily: 'CircularStdBold',
                textAlign: 'center', marginBottom: "0", backgroundColor: 'transparent', 
              }}>Oops!</Typography>
              <div className="text" data-testid="subCategoryRequired"
                style={{
                  marginTop: 10, fontFamily: 'CircularStd',
                  color: "#5A5A5A", textAlign: 'center', 
                  lineHeight: '19px', maxWidth: 380, fontSize: 14,
                }}>
                {this.state.errorMessage}
              </div>
            </Box>
          </ResponsiveDialogContent>
        </Dialog>

        <Dialog id="model_add" maxWidth="md"
          open={this.state.dupModalOpen} 
          onClose={this.dupModalClose}
          style={modalDialogeStlye}>
          <ResponsiveDialogContent className="pt-100" style={modalOuterBoxStyle}>
            <ModalInnerBoxStlye data-testid="hideModal" onClick={this.dupModalClose} >
              <Image source={imgClose} resizeMode="cover" style={modalImageStyle} />
            </ModalInnerBoxStlye>
            <ModalInnerBoxDirectionStlye className="contentContainer">
              <Box className="contentImage" style={modalDirInnerStyle}>
                <ClearIcon style={clearIconStyle} />
              </Box>
              <Typography className="heading" variant="h5" component="h2" style={modalTitle}>{configJSON.duplicateFileTitle}</Typography>
              <ModalSubtitleStlye className="text">{configJSON.duplicateFileSubtitle}</ModalSubtitleStlye>
            </ModalInnerBoxDirectionStlye>
          </ResponsiveDialogContent>
        </Dialog>
        
        {<Dialog id="model_add" maxWidth="md"
        data-test-id="meetWithManagerPopup"
          open={this.state.meetWithExpertDialog} onClose={this.cancelMeetWithManager}
          style={{
            border: '3px solid black'
          }}>
          <ResponsiveDialogContent className="pt-65 pb-50"
            style={{
              maxWidth: '100%', minHeight: '465px', border: '3px solid black', width: '480px',
              display: 'flex', alignItems: 'center', boxSizing: 'border-box', flexDirection: 'column',
            }}>
            <div className="closeWrapper" data-testid="hideModal" onClick={this.cancelMeetWithManager} style={{
              position: "absolute", right: '20px', cursor: "pointer", top: '20px',
            }}>
              <Image source={imgClose} resizeMode="cover" style={{ width: '20px', height: '20px', }}
              />
            </div>
            <Box className="contentContainer" style={{
              gap: '25px', flexDirection: 'column', alignItems: 'center',
              justifyContent: 'center', display: 'flex',
            }}>
              <Box className="warningLogo" style={{
                backgroundColor: '#BDB9FB', display: "flex", alignItems: "center", width: '85px',
                border: 'solid 3px #000', height: "85px", justifyContent: "center",
              }}>
                <div data-testid="calendarIcon">
                  <Image source={meetingCalendar} resizeMode="cover" style={{ width: '40px', height: '41.64px', }}
                  />
                </div>
              </Box>
              <Typography className="heading" variant="h5" component="h2" style={{
                color: "#000", textAlign: 'center',
                fontFamily: 'CircularStdBold', backgroundColor: 'transparent', marginBottom: "0", fontSize: 30,
              }}>{configJSON.meetWithManagerTitle}</Typography>
              <div className="text" style={{
                color: "#5A5A5A", maxWidth: 380, fontSize: 14, marginTop: 10, fontFamily: 'CircularStd',
                lineHeight: '19px', textAlign: 'center',
              }}> {this.state.warningMessage}</div>
            </Box>
            <div className="proceedButton" style={{ display: 'flex', gap: '20px', paddingTop: "20px", justifyContent: "center", marginTop: 'auto', flexWrap: "wrap", }}>
              <View>
                <div style={{ width: 150 }} className={`buttonWrapper w-220 btn-subcategory3`}>
                  <div className={`buttonOutline subcategoryBg3`}></div>

                  <Button
                    data-test-id="confirmBtn"
                    type="submit"
                    className="btn-sm w-150"
                    onClick={this.cancelMeetWithManager}
                  >Cancel</Button>
                </div>
              </View>

              <View>
              <Tooltip title={this.isValidMeetLink() ? "" :"No meeting link added for your company. Please contact your account manager"} placement="top">
                <div className={`buttonWrapper w-150`} style={{ width: 199}}>
                  <div className="buttonOutline" style={!this.isValidMeetLink() ? { backgroundColor: '#5A5A5A' } :{ backgroundColor: '#00BFBB' } }></div>
                  <Button
                    data-test-id="testConfirmBtn"
                    type="submit"
                    className="btn-sm w-150"
                    style={!this.isValidMeetLink() ? {backgroundColor:"#D9D9D9" } : {background:'fff'}}
                    disabled={!this.isValidMeetLink()}
                    onClick={this.meetLink}
                  >Schedule Meeting</Button>
                </div>
                </Tooltip>
              </View>
            </div>

          </ResponsiveDialogContent>
        </Dialog>}

        <Dialog id="model_add" maxWidth="md" data-testid="dialogOops"
          onClose={this.closeLimitReachedPopup} open={this.state.maxUploadLimitReached} style={{ border: '3px solid black' }}>
          <ResponsiveDialogContent style={{
            width: '480px', maxWidth: '100%', alignItems: 'center', flexDirection: 'column', border: '3px solid black', minHeight: '465px',
            display: 'flex', boxSizing: 'border-box',
          }} className="pt-100">
            <div
              data-testid="hideModalOops"
              className="closeWrapper"
              onClick={this.closeLimitReachedPopup} style={{ position: "absolute", top: '20px', right: '20px', cursor: "pointer", }}>
              <Image
                source={imgClose}
                resizeMode="cover"
                style={{ height: '20px', width: '20px', }} />
            </div>
            <Box
              className="contentContainer"
              style={{
                gap: '25px',
                flexDirection: 'column', justifyContent: 'center',
                alignItems: 'center', display: 'flex',
              }}>
              <Box
                className="contentImage"
                style={{ width: 'fit-content', border: 'solid 3px #000', backgroundColor: '#FF5B74', padding: '10px', }}>
                <ClearIcon style={{ fontSize: '48px', width: '60px', height: '60px', color: 'black' }} />
              </Box>
              <Typography
                className="heading" variant="h5" component="h2"
                style={{
                  fontFamily: 'CircularStdBold', color: "#000", textAlign: 'center', fontSize: 30, marginBottom: "0", backgroundColor: 'transparent',
                }}> Limit Reached </Typography>
              <div style={{
                lineHeight: '19px', maxWidth: 380, fontSize: 14,
                color: "#5A5A5A", textAlign: 'center', marginTop: 10, fontFamily: 'CircularStd',
              }} className="text" > You can upload a maximum of 10 files. Please check you have uploaded the 10 files you wish to proceed with before continuing. </div>
            </Box>
          </ResponsiveDialogContent>
        </Dialog>

        <Dialog
          id="model_add" maxWidth="md"
          open={this.state.openDialogName === "OpenICalendar"}
          onClose={this.onCancelCalendar}
          style={
            {
              border: '3px solid black'
            }} scroll="body">
          <ResponsiveDialogContent className="pt-80 pb-30"
            style={{
              alignItems: 'center', border: '3px solid black', width: '480px',
              display: 'flex', boxSizing: 'border-box', flexDirection: 'column', minHeight: '465px', maxWidth: '100%'
            }}>
            <div className="closeWrapper" data-testid="hideModal" onClick={this.onCancel} style={{
              top: '20px', right: '20px',
              cursor: "pointer", position: "absolute",
            }}>
              <Image source={imgClose} resizeMode="cover" style={{
                height: '20px',
                width: '20px',
              }} />
            </div>
            <Box className="contentContainer" style={{
              display: 'flex', gap: '25px', flexDirection: 'column', maxWidth: '100%',
              alignItems: 'center', justifyContent: 'center',
            }}>
              <Box className="calendarImage" style={{
                backgroundColor: '#BDB9FB', padding: '20px',
                width: 'fit-content', border: 'solid 3px #000',
              }}>
                <Image
                  source={groupmeeticon}
                  resizeMode="cover"
                  style={{
                    height: 40,
                    width: 40,
                  }} />
              </Box>
              <Typography className="heading" variant="h5" component="h2" style={{
                marginBottom: "0", color: "#000", fontSize: 30, fontFamily: 'CircularStdBold',
                backgroundColor: 'transparent', textAlign: 'center',
              }}>Add to Calender</Typography>
              <div className="text" style={{
                color: "#5A5A5A", textAlign: 'center', fontFamily: 'CircularStd', maxWidth: 387,
                lineHeight: '28px', fontSize: 14
              }}>To add this event to your calendar, select your calendar from the dropdown list.
              </div>
              {this.renderCalendarType()}
              <div style={{
                display: 'flex',
                maxWidth: '100%'
              }}>
                <View style={{ maxWidth: '100%' }}>
                  {this.state.calendarType != "calendarIcon" && (
                    <div className={`buttonWrapper w-150 btn-blue ContinueButton`}
                      style={{
                        width: '191px', maxWidth: '100%', marginTop: '10px', marginBottom: '30px'
                      }}>
                      <div className="buttonOutline"></div>
                      <Button
                        data-test-id="testConfirmBtn"
                        type="submit"
                        className="btn-sm w-150"
                        onClick={this.handleCalendarChange}
                      >
                        {this.renderCalendarButton()}
                      </Button>
                    </div>
                  )}
                </View>
              </div>
            </Box>
          </ResponsiveDialogContent>
        </Dialog>

        <Dialog id="model_add" maxWidth="md"
          open={this.state.openDialogName === "NotFound"}
          onClose={this.closeCustomFormPopup}
          style={{ border: '3px solid black' }}>
          <ResponsiveDialogContent className="pt-100" style={{
            alignItems: 'center', maxWidth: '100%',
            width: '480px', minHeight: '465px', display: 'flex', boxSizing: 'border-box',
            flexDirection: 'column', border: '3px solid black',
          }}>
            <div className="closeWrapper" data-testid="hideModal" onClick={this.closeCustomFormPopup} style={{
              top: '16px', right: '16px', position: "absolute", cursor: "pointer",
            }}>
              <Image source={imgClose} resizeMode="cover" style={{
                height: '20px',
                width: '20px',
              }} />
            </div>
            <Box className="contentContainer" style={{
              display: 'flex', alignItems: 'center',
              justifyContent: 'center', flexDirection: 'column', gap: '25px'
            }}>
              <Box className="contentImage" style={{
                backgroundColor: '#FF5B74', border: 'solid 3px #000', padding: '10px',
                width: 'fit-content',
              }}>
                <ClearIcon style={{
                  fontSize: '48px', width: '60px', color: 'black',
                  height: '60px',
                }} />
              </Box>
              <Typography className="heading" variant="h5" component="h2" style={{ fontSize: 30, color: "#000", backgroundColor: 'transparent', fontFamily: 'CircularStdBold', marginBottom: "0", textAlign: 'center' }}>Oops!</Typography>
              <div className="text" style={{ color: "#5A5A5A", lineHeight: '19px', textAlign: 'center', fontFamily: 'CircularStd', fontSize: 14, marginTop: 10, maxWidth: 380, }}>{configJSON.inquiryNotFoundMessage}</div>
            </Box>
          </ResponsiveDialogContent>
        </Dialog>

        {this.renderCompanySuspendedDialog()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const accountSuspendedModal = {
  container:{
    maxWidth: '100%', 
    width: '480px', 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: "center",
    border: '3px solid black', 
    boxSizing: 'border-box' as "border-box", 
    minHeight: '465px',
  },
  modalContentWrapper:{
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  crossMark:{
    backgroundColor: '#FF5B74',
    border: 'solid 3px #000',
    width: 'fit-content', 
    padding: '12px',
    marginBottom:12
  },
  crossMarkIcon:{
    height: '55px',
    color: 'black',
    width: '55px',
  },
  crossMarkCloseBtn:{
    position: "absolute" as "absolute", 
    top: '3px', 
    right: '3px'
  },
  crossMarkCloseIcon:{ 
    fontWeight: 900, 
    color: "black", 
    fontSize: "25x", 
    width: "25px", 
    height: "25px"
  },
  suspendedTitle:{
    fontSize:"clamp(1.5rem, 1.3917rem + 0.5333vw, 1.875rem)",
    color:"black",
    padding:10,
    fontWeight:700,
    fontFamily: 'CircularStd',
    marginBottom:14
  },
  suspendedMessage:{
    textAlign:"center" as "center",
    fontSize:"14px",
    fontFamily: 'CircularStd',
    color:"#5A5A5A",
    paddingInline:"20px"
  },
}

const webStyles = {
  form: {
    width: "100%",
    paddingLeft: 8,
    paddingRight: 8
  },
  whiteBg: {
    backgroundColor: '#fff'
  }
}

const dividerCss = {
  margin : "20px 0px"
};

const uploadModalHeaderCss = {
  fontFamily: "CircularStdBold",
  color: "#000000",
  paddingTop: "20px",
  fontSize: 30
};

const rbsCostStyle = {
  fontFamily: "CircularStdBold",
  fontSize: 16,
  color: '#000'
};

const styles = StyleSheet.create({
  tableProvision: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  fontSm: {
    fontSize: 12,
    fontFamily: "CircularStd",
  },
  fontm: {
    fontSize: 16,
    fontFamily: "CircularStdBold",
  },
  accordionTittle: {
    fontFamily: "CircularStdBold",
    color: "#594FF5",
    fontSize: 16,
    paddingBottom: 0,
    textTransform: "capitalize",
    top: "-7px",
    position: "relative"
  },
  flexColumn: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  MuiMenuList: {
    display: 'flex'
  }
})
const CustomTextInputStyle = styled(TextareaAutosize)({
  backgroundColor: "#fff", minHeight: "22px", position: "relative", zIndex: 1, borderColor: "#000", width: "100%", paddingLeft: "5px", paddingRight: "5px", borderStyle: "solid", borderWidth: 3, fontFamily: 'CircularStd',
  fontSize: 14, paddingTop: 30, lineHeight: 1, "& .MuiInputBase-fullWidth": { margin: 0 }, "& inpur:before": { content: "none" }, "& .MuiInputAdornment-positionEnd": { position: "absolute", zIndex: 1, right: 0, }
});
const getStatusColor = (status: any) => {
  switch (status) {
    case 'approved':
      return '#00BFBB';
    case 'rejected':
      return '#F87171';
    case 'draft':
      return '#FFC500';
    case 'pending':
      return '#B0B0B0';
    default:
      return '#FFFFFF';
  }
};
const InputContainer = styled(`div`)({
  position: "relative",
  display: "inlineBlock",
})


const InputField = styled(`input`)({
  width: "100%",
  height: "100%",
  border: "3px solid black",
  outline: "none",
  boxSizing: "border-box",
  padding: "12 10 10 10",
  fontFamily: "CircularStd",
  fontSize: "14px",
  fontWeight: 450,
  '&::placeholder': {
    color: "#999",
  },
  '&:focus': {
    outline: "none"
  },
})

const DropdownContent = styled(`div`)({
  display: "none",
  position: "absolute",
  backgroundColor: "#fff",
  borderTop: "none !important",
  width: "98.8%",
  zIndex: 1,
  borderRight: "3px solid black",
  borderLeft: "3px solid black",
  borderBottom: "3px solid black",
  overflowY: "scroll",
})

const DropdownList = styled(`ul`)({
  listStyleType: "none",
  padding: 0,
  margin: 0,
})

const DropdownItem = styled(`li`)({
  padding: "10px",
  cursor: "pointer",

  '&:hover': {
    backgroundColor: "#BDB9FB",
  }
})

const CustomGridItem = styled(Grid)({
  "&.MuiGrid-grid-xs-3": {
    maxWidth: "none !important",
  }
})

const EventDetailsContainer = styled(Box)({
  display: 'flex',
  paddingLeft: '28px',
  paddingRight: '34px',
  "@media screen and (max-width:750px)": {
    flexDirection: 'column',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  "@media screen and (max-width:450px)": {
    paddingLeft: 0,
    paddingRight: 0,
  }
})

const EventInfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  '& .home': {
    paddingLeft: 7,
    marginBottom: 10,
    paddingTop: 10
  },
  '& .eventTitle': {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  "@media screen and (max-width:750px)": {
    '& .eventTitle': {
      marginTop: '10px',
      marginBottom: '10px'
    },
  },
  "@media screen and (max-width:450px)": {
    '& .home': {
      paddingLeft: 0,
      paddingTop: 0
    }
  }
})

const ResponsiveAmountGrid = styled(Grid)({
  marginTop: 0,
  marginLeft: 'auto',
  paddingRight: '30px',
  width: '300px',
  "@media screen and (max-width:750px)": {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: 0,
    paddingRight: 0,
    maxWidth: '100%'
  },
})

const CustomFormContainer = styled(View)({
  paddingLeft: 28,
  paddingRight: 34,
  '& .uploadButton': {
    minWidth: '70px'
  },
  '& .actionButtons': {
    display: "flex",
    justifyContent: "end",
    flexDirection: "row",
    marginLeft: 'auto',
    gap: '24px',
    marginRight: '10%'
  },
  "@media screen and (max-width:750px)": {
    paddingLeft: '20px',
    paddingRight: '20px',
    '& .actionButtons': {
      justifyContent: 'start',
      marginRight: 0,
      marginLeft: 0,
      flexWrap: 'wrap'
    }
  },
  "@media screen and (max-width:450px)": {
    paddingLeft: 0,
    paddingRight: 0,
    '& .custom-form': {
      boxSizing: 'border-box'
    },
    '& .tellUsProject': {
      flexBasis: '100%',
    },
    '& .uploadContainer': {
      maxWidth: '100%',
      flexBasis: '100%'
    },
    '& .uploadButton': {
      width: '100%',
      maxWidth: 'auto'
    },
    '& .actionButtons': {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& .customInputFields': {
      maxWidth: '100%',
      flexBasis: '100%'
    },
  }
})

const UploadDialogContent = styled(DialogContent)({
  paddingLeft: "40px",
  border: "solid 3px #000",
  overflowX: "hidden",
  '& .uploadFileContainer': {
    paddingBottom: '100px'
  },
  '& .uploadedFiles': {
    borderColor: "#5A5A5A",
    borderWidth: 1,
    borderStyle: "solid",
    padding: '25px 15px',
    marginBottom: 30
  },
  '& .mobileTitle': {
    display: 'none'
  },
  "& .fileStatusInnerContainer": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: 'wrap'
  },
  '& .duplicateFiles': {
    borderColor: "#FF5B74",
  }, '& .fileContainerClass': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  "& .cancelProgress": {
    width: "100%",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#FF5B74 !important"
    }
  },
  "& .imageStyle": {
    marginRight: 10,
    width: "46px"
  },
  "& .fileStatusNameStyle": {
    color: "#FF5B74",
    fontSize: 14,
    fontFamily: "CircularStd"
  },
  "& .fileNameStyle": {
    color: "#5A5A5A",
    fontSize: 14,
    fontFamily: "CircularStd"
  },
  '& .fileStatusStyle': {
    borderWidth: 1, 
    borderStyle: "solid", 
    padding: '25px 15px', 
    marginBottom: 30 
  },
  "@media screen and (max-width:750px)": {
    '& .uploadFileContainer': {
      paddingTop: '50px',
      paddingBottom: '70px'
    },
    '& .uploadFilesBox': {
      '& >div >div:last-child': {
        width: '90%'
      }
    },
    '& .uploadedFiles': {
      padding: 'min(25px,2vw) min(15px,1vw)',
      marginBottom: 'min(25px,3.5vw)'
    },
    '& .uploadedFiles img': {
      width: ' min(45px,6vw) !important',
      marginRight: 'min(8px,0.7vw) !important',
    }
  },
  "@media screen and (max-width:500px)": {
    '& .uploadTitleConatiner': {
      display: 'none'
    },
    '& .mobileTitle': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .uploadFileContainer': {
      paddingTop: '20px',
      paddingBottom: '30px'
    },
    '& .uploadFileContainer >div': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& .uploadFileContainer >div >div': {
      maxWidth: '100%',
      flexBasis: '100%'
    },
    '& .removeBlockMobile': {
      display: 'none'
    },
    '& .uploadFilesBox img': {
      width: 'min(90px, 20vw) !important'
    },
    '& .uploadedFiles': {
      padding: 'min(25px,4vw) min(15px,3vw)',
      marginBottom: 'min(25px,5vw)'
    },
    '& .uploadedFiles img': {
      width: 'min(45px,8vw) !important',
      marginRight: 'min(8px,1vw) !important'
    }
  }
})

const InfoPaper = styled(Paper)({
  padding: '8px 16px',
  marginTop: '3px',
  display: 'flex',
  alignItems: 'start',
  boxShadow: '0px 6px 15px -3px rgba(0, 0, 0, 0.15)',
  borderRadius: '0',
  '& .infoIcon': {
    color: '#000',
    marginRight: '8px',
    width: '24px'
  },
  '& .infoText': {
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'Circular Std, sans-serif',
    fontWeight: 450
  },
  '& .infoButton': {
    textTransform: 'none',
    color: '#594FF5',
    fontWeight: 'bold',
    width: '100%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none'
    },
    '&:visited': {
      backgroundColor: 'transparent',
      textDecoration: 'none'
    }
  }

})


const ReviewDialogContent = styled(DialogContent)({
  '& .priceDetailsContainer': {
    display: 'flex',
    width: '100%',
    padding: '16px',
  },
  '& .reviewActionButtons': {
    gap: '20px',
    display: "flex",
    justifyContent: "end",
    flexDirection: "row",
    flexWrap: 'wrap',
    width: '100%',
    paddingRight: "30px",
    marginTop: "20px",
    marginBottom: "15px"
  },
  "@media screen and (max-width:750px)": {
    '& .reviewInputFields': {
      gridTemplateColumns: 'repeat(3, 1fr) !important'
    },
    '& .priceDetailsContainer': {
      flexDirection: 'column',
      flexWrap: 'nowrap'
    },
    '& .priceDetailsContainer >div': {
      maxWidth: '100%',
      flexBasis: '100%'
    },
    '& .reviewActionButtons >div': {
      width: '160px'
    },
    '& .reviewActionButtons .backButton': {
      width: '60px'
    },
    "@media screen and (max-width:500px)": {
      '& .reviewActionButtons': {
        justifyContent: "center",
        flexDirection: 'column',
        alignItems: 'center'
      },
      '& .reviewInputFields': {
        gridTemplateColumns: 'repeat(2, 1fr) !important',
        paddingRight: '0 !important'
      },

    }

  }
})

const ResponsiveDialogContent = styled(DialogContent)({
  '&.pt-65': {
    paddingTop: '65px'
  },
  '&.pb-80': {
    paddingBottom: '80px'
  },
  '&.pt-100': {
    paddingTop: '100px',
  },
  '&.pt-80': {
    paddingTop: '80px'
  },
  '&.pb-50': {
    paddingBottom: '50px'
  },
  '@media only screen and (max-width:500px)': {
    width: '390px !important',
    minHeight: '340px !important',
    gap: '20px !important',
    '&.pt-65': {
      paddingTop: '55px'
    },
    '&.pt-100': {
      paddingTop: '80px',
    },
    '&.pb-35,&.pb-80': {
      paddingBottom: '40px'
    },
    '&.pb-30,&.pb-50': {
      paddingBottom: '30px'
    },
    '& .closeWrapper': {
      right: '15px !important',
      top: '15px !important'
    },
    '& .closeWrapper >div': {
      height: '18px !important',
      width: '18px !important'
    },
    '& .contentContainer': {
      gap: '18px !important'
    },
    '& .contentImage': {
      padding: '7px !important'
    },
    '& .contentImage >svg': {
      height: '35px !important',
      width: '35px !important'
    },
    '& .calendarImage >div': {
      height: '30px !important',
      width: '30px !important'
    },
    '& .calendarImage': {
      padding: '12px !important'
    },
    '& .heading': {
      fontSize: '24px !important',
      lineHeight: '36px !important'
    },
    '& .text': {
      fontSize: '14px !important',
      lineHeight: '23px !important',
      marginTop: '5px !important',
      maxWidth: '330px !important'
    },
    '& .icalendar-select': {
      maxWidth: '200px !important'
    },
    '& .ContinueButton': {
      width: '150px !important',
      marginBottom: '15px !important'
    },
    '& .warningLogo': {
      width: '60px !important',
      height: '60px !important'
    },
    '& .warningLogo >h2': {
      fontSize: '40px !important'
    },
    '& .proceedButton': {
      marginTop: "20px"
    },
    '& .proceedButton >div>div': {
      width: '100px !important'
    },
    '& .scheduleButton >div>div': {
      width: '160px !important'
    }
  }
})
const menuProps: Partial<MenuProps> = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'center' },
  variant: 'menu',
  className: 'multiselect-popover-custom',
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      width: '100px',
      margin: '0px',
      border: '3px solid black',
      borderTop: 0,
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
};
const CustomListItem = styled(MenuItem)({
  '&.disabled-option': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  '&.wrap > div > span': {
    whiteSpace: 'pre-wrap',
  }
})

const UploadButtonDiv = styled(`div`)({
  border: "3px solid #000",
  height: "33px",
  position: "absolute",
  right: "-4px",
  bottom: "1.5px",
  width: "98%",
  backgroundColor: "#594FF5",
  zIndex: 0,
})

const AttachmentDiv = styled(`div`)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 50%)",
  gridTemplateRows: "repeat(5, auto)",
  gap: '16px',
  paddingRight: '30px',
  boxSizing: "border-box",
  '& .item': {
    border: '3px solid #000',
    padding: "6px",
    backgroundColor: '#F0F0F0',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "10px"
  },
  '& .item:nth-child(1)': { gridRow: 1, gridColumn: 1 },
  '& .item:nth-child(2)': { gridRow: 2, gridColumn: 1 },
  '& .item:nth-child(3)': { gridRow: 3, gridColumn: 1 },
  '& .item:nth-child(4)': { gridRow: 4, gridColumn: 1 },
  '& .item:nth-child(5)': { gridRow: 5, gridColumn: 1 },

  '& .item:nth-child(6)': { gridRow: 1, gridColumn: 2 },
  '& .item:nth-child(7)': { gridRow: 2, gridColumn: 2 },
  '& .item:nth-child(8)': { gridRow: 3, gridColumn: 2 },
  '& .item:nth-child(9)': { gridRow: 4, gridColumn: 2 },
  '& .item:nth-child(10)': { gridRow: 5, gridColumn: 2 },
})

const modalDialogeStlye = {
  border: '3px solid black'
};

const modalOuterBoxStyle = {
  border: '3px solid black',
  minHeight: 350,
};

const ModalInnerBoxStlye = styled(`div`)({
  position: "absolute",
  cursor: "pointer",
  top: "20px",
  right: "20px",
});

const modalImageStyle = {
  height: '20px',
  width: '20px',
};

const ModalInnerBoxDirectionStlye = styled(`div`)({
  gap: '25px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const modalDirInnerStyle = {
  backgroundColor: '#FF5B74',
  padding: '10px',
  width: 'fit-content',
  border: 'solid 3px #000',
};

const clearIconStyle = {
  height: '60px',
  fontSize: '48px',
  width: '60px',
  color: 'black',
};

const modalTitle = {
  color: "#000",
  fontSize: 30,
  fontFamily: 'CircularStdBold',
  backgroundColor: 'transparent',
  marginBottom: 0,
};


const timeInputStyle = {
  box: {
    position: "relative" as "relative",
    border: "3px solid black",
    height: 35
  },
  timeZone: {
    color: "black",
    position: "absolute" as "absolute",
    background: "white",
    top: "47%",
    fontSize: "14px",
    right: "10px",
    transform: "translateY(-50%)",
  },
  inputStyle: {
    paddingRight: "30px",
    padding: "5px",
    outline: "none",
    border: "none",
    fontFamily: "CircularStd",
    paddingBottom: 9,
    paddingTop: 9,
    width: "100%",
    height:"100%",
    paddingLeft: 5,
    borderRadius: 0,
    fontSize: "14px",
  }
};

const ModalSubtitleStlye = styled(`div`)({
  color: "#5A5A5A",
  textAlign: 'center',
  marginTop: 10,
  fontFamily: 'CircularStd',
  lineHeight: '19px',
  maxWidth: 380,
  fontSize: 14,
})

// Customizable Area End
